import React, { useEffect } from 'react';
import {
  HashRouter as Router,
  Switch,
  Redirect,
  Route
} from "react-router-dom";
import { deviceDetect } from 'react-device-detect';
import { useStore } from 'app/store';
import TimeMe from "timeme.js";

import Error from 'components/Error';
import Loading from 'components/Loading';
import TakeHome from 'components/TakeHome';
import TakeHomeM7 from 'components/TakeHomeM7';
import Milestone1 from 'collectors/milestone1';
import Milestone2 from 'collectors/milestone2';
import Milestone3 from 'collectors/milestone3';
import Milestone4 from 'collectors/milestone4';
import Milestone5 from 'collectors/milestone5';
import Milestone6 from 'collectors/milestone6';
import Milestone7 from 'collectors/milestone7';
import Milestone8 from 'collectors/milestone8';
import Milestone9 from 'collectors/milestone9';
import Milestone10 from 'collectors/milestone10';
import Milestone11 from 'collectors/milestone11';
import Milestone12 from 'collectors/milestone12';
import MilestoneOmega from 'components/milestones/milestoneOmega';
import { MILESTONE_OMEGA_NUMBER } from 'components/milestones/milestoneOmega/vars';
import Layout from 'components/Layout';
import Dashboard from 'components/Dashboard';
import LoginAuth0 from 'components/LoginAuth0';
import MaintenanceMode from 'components/MaintenanceMode';
import Contact from 'components/Contact';

import { postEvent, configureAuth, getAlerts } from 'lib/API';
import { useAuth0 } from "@auth0/auth0-react";

function App() {
  const [user, setUser] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [unrecoverableError, setUnrecoverableError] = React.useState(false);
  const maintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE;
  const { getAccessTokenSilently, isAuthenticated, isLoading: auth0_loading, logout, user: auth0_user } = useAuth0();
  // Zustand state
  const setAlerts = useStore(state => state.setAlerts)

  const updateUser = async () => {
    try {
      if (isAuthenticated) {
        setUser(auth0_user);
        getAlerts().then((response) => {
          const sortedAlerts = response.sort((a, b) => {
            return (b.created_at ? new Date(b.created_at) : new Date(null)) - (a.created_at ? new Date(a.created_at) : new Date(null));
          });
          setAlerts(sortedAlerts);
        });
        setLoading(false);
      } else {
        setUser(null);
        setLoading(false);
      }
    } catch {
      setUser(null);
      setLoading(false);
    }
  }

  const getAccessToken = async() => {
    try {
      return await getAccessTokenSilently();
    } catch (e) {
      console.warn("Failed to get access token", e);
    }
    return null;
  }

  useEffect(() => {
    configureAuth(getAccessToken);
    updateUser(); // check after initial load and authentication
    if (isAuthenticated) {
      postEvent('LOAD', {
        device: deviceDetect(),
        referrer: document.referrer,
        path: window.location.pathname,
        language: navigator.language || navigator.userLanguage,
        localtime: Date()
      });
    }
  }, [auth0_loading, isAuthenticated]);

  useEffect(() => {
    /**
     * Configuration of idle time before we track
     * the user as inactive
     */
    TimeMe.initialize({
      idleTimeoutInSeconds: 300 // seconds
    });
  }, []);

  useEffect(() => {
    TimeMe.callWhenUserLeaves(() => {
      const timeReport = TimeMe.getTimeOnAllPagesInSeconds();
      timeReport.forEach(({ pageName, timeOnPage }) => {
        if (pageName !== 'default-page-name') {
          postEvent(`TIME_${pageName}_PAGETOTAL`, { timeOnPage: timeOnPage.toString() })
        }
      })
      const timeActiveSession = timeReport.reduce((prev, { timeOnPage }) => prev + timeOnPage, 0);
      postEvent('TIME_ACTIVE_SESSION', { timeOnPage: timeActiveSession.toString() });
    });

    TimeMe.callWhenUserReturns(() => {
      TimeMe.resetAllRecordedPageTimes();
    });
  }, []);

  const render = () => {
    if (maintenanceMode === 'true') {
      return (
        <MaintenanceMode />
      )
    } else if (unrecoverableError) {
      return (
        <Error />
      );
    } else if (loading || auth0_loading) {
      return (
        <Loading />
      );
    } else if (!(isAuthenticated && user)) {
      return (
        <LoginAuth0 setLoading={setLoading} />
      );
    } else {
      return (
        <>
          <Layout preferredName={user.nickname}>
            <Switch>
              <Route path="/dashboard/:activeTab?">
                <Dashboard user={user} preferredName={user.nickname} />
              </Route>
              <Route path="/contact">
                <Contact user={user} preferredName={user.nickname} />
              </Route>
              <Route path="/take-home">
                  <TakeHome preferredName={user.nickname} />
              </Route>
              <Route path="/take-home-m7">
                  <TakeHomeM7 preferredName={user.nickname} />
              </Route>
              <Route path="/milestone1">
                  <Milestone1 />
              </Route>
              <Route path="/milestone2">
                  <Milestone2 />
              </Route>
              <Route path="/milestone3">
                <Milestone3 />
              </Route>
              <Route path="/milestone4">
                <Milestone4 />
              </Route>
              <Route path="/milestone5">
                <Milestone5 />
              </Route>
              <Route path="/milestone6">
                <Milestone6 preferredName={user.nickname} />
              </Route>
              <Route path="/milestone7">
                <Milestone7 preferredName={user.nickname} />
              </Route>
              <Route path="/milestone8">
                <Milestone8 preferredName={user.nickname} />
              </Route>
              <Route path="/milestone9">
                <Milestone9 preferredName={user.nickname} />
              </Route>
              <Route path="/milestone10">
                <Milestone10 preferredName={user.nickname} />
              </Route>
              <Route path="/milestone11">
                <Milestone11 preferredName={user.nickname} />
              </Route>
              <Route path="/milestone12/finalize-list-of-schools">
                <Milestone12 preferredName={user.nickname} schoolsList={true} />
              </Route>
              <Route path="/milestone12">
                <Milestone12 preferredName={user.nickname} />
              </Route>
              <Route path="/milestone13">
                <MilestoneOmega preferredName={user.nickname} />
              </Route>
              <Route>
                <Redirect to={`/dashboard`} />
              </Route>
            </Switch>
          </Layout>
        </>
      );
    }
  }

  return (
    <Router>
      <div className="App">
        { render() }    
      </div>
    </Router>
  );
}
//Comment
export default App;
