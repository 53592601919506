import React from "react";
import * as Survey from "survey-react";
import NumberFormat from 'react-number-format';

export class DateModel extends Survey.Question {
  getType() {
    return "date";
  }

  validateValueCallback() {
    if (!this.isRequired && !this.questionValue) {
      return false;
    }
    let noUnderscoreStr = this.questionValue.replace(/_/g, '');
    let parseDate = (str) => {
      let newStr = str.replace(/\//g, '');
      let month = newStr.substring(0, 2);
      let day = newStr.substring(2, 4);
      let year = newStr.substring(4, 8);
      return window.Date.parse(year + "-" + month + "-" + day);
    }
    if (noUnderscoreStr.length === 10) {
      let d = parseDate(this.questionValue);

      // maxDay can be a string representing a date with format MMDDAAAA
      // or the string 'today'
      if (this.maxDate) {
        let max_date_parsed = this.maxDate === 'today'
          ? window.Date.now()
          : parseDate(this.maxDate);
        if (d > max_date_parsed) {
          return new Survey.CustomError(this.maxDateErrorText ? this.maxDateErrorText : `Maximum date allowed is ${this.maxDate}`, this.survey);
        }
      }

      // min_day is a string representing a date with format MMDDAAAA
      if (this.minDate) {
        let min_date_parsed = parseDate(this.minDate);
        if (d < min_date_parsed) {
          return new Survey.CustomError(this.maxDateErrorText ? this.maxDateErrorText : `Minimum date allowed is ${this.maxDate}`, this.survey);
        }
      }
      
      if (!(d > window.Date.parse("1900-01-01"))) {
        return new Survey.CustomError('Please enter a valid date.', this.survey);
      }
      return false;
    }
    return new Survey.CustomError('Please enter a valid date.', this.survey);
  }
}

export class Date extends Survey.SurveyElementBase {
  get question() {
    return this.props.question;
  }
  render() {
    if (!this.question) return null;
    var cssClasses = this.question.cssClasses;

    return (
      <div className={cssClasses.root}>
        <NumberFormat
          format="##/##/####"
          placeholder="MM/DD/YYYY"
          defaultValue={this.question.value}
          mask="_"
          allowNegative={false}
          allowLeadingZeros={true}
          className={`form-control form-control-default`}
          onValueChange={values => {this.question.value = values.formattedValue}}
        />
      </div>
    );
  }
}

Survey.Serializer.addClass(
  "date",
  [
    { name: "maxDate" },
    { name: "maxDateErrorText" },
    { name: "minDate" },
    { name: "minDateErrorText" }
  ],
  function() {
    return new DateModel("");
  },
  "question"
);


Survey.ReactQuestionFactory.Instance.registerQuestion("date", props => {
  return React.createElement(Date, props);
});
