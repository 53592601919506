export function thousandsSeparator(num) {
  if (!num) return num;
	return String(num).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export function joinWithAnd(arr) {
  if (arr.length === 0){
    return '';
  } else if (arr.length === 1) {
    return arr[0];
  } else if (arr.length === 2) {
    return arr.join(' and ');
  } else {
    return arr.slice(0, -1).join(', ') + ' and ' + arr.slice(-1);
  }
}

export function firstLetterUpper(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function snakeToText(str) {
  return firstLetterUpper(str.replace(/_/g, ' '));
}