import React from "react";
import * as Survey from "survey-react";
import cn from "classnames";
import styles from 'styles/accordion.module.scss';
import Downshift from 'downshift';
import { CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';

export class RadiogroupDrawerModel extends Survey.Question {
  getType() {
    return "radiogroup-drawer";
  }
}

function validateCondition(condition, valuesHash) {
  const [variable, operator, value] = condition.split(" ");
  if (operator === 'contains') {
    return valuesHash[variable]?.includes(value);
  }
  if (operator === 'notcontains') {
    return valuesHash[variable] && !valuesHash[variable].includes(value);
  }
  if (operator === '=') {
    return valuesHash[variable] === value;
  }
  return false;
}

export class RadiogroupDrawer extends Survey.SurveyElementBase {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: this.question.choices.map(() => false),
    };
  }
  get question() {
    return this.props.question;
  }
  render() {
    const valuesHash = this.question.surveyValue?.valuesHash;
    if (!this.question) return null;
    var cssClasses = this.question.cssClasses;

    const handleChange = (e) => {
      const { value } = e.target;
      this.question.value = value;
    };

    return (
      <div className={cssClasses.root}>
        {this.question.choices.map((choice, idx) => {
          const validElements = choice.contents.filter((elem) => {
            if (!elem.condition) return true;
            return validateCondition(elem.condition, valuesHash);
          });
          return (
            <div key={choice.value}>
              <div className={styles.radiogroupRow}>
                <input
                  className={styles.radio}
                  type="radio"
                  id={`choice_${choice.value}`}
                  name="fav_language"
                  value={choice.value}
                  onChange={handleChange}
                  checked={this.question.value === choice.value}
                />
                <label className={styles.label} for={`choice_${choice.value}`}>{choice.text}</label>
                <button
                  onClick={() => {
                    this.setState({isOpen: [...this.state.isOpen.slice(0, idx), !this.state.isOpen[idx], ...this.state.isOpen.slice(idx + 1)]});
                  }}
                  className={cn({
                    [styles.radiogroupDrawerButton]: true,
                    [styles.active]: this.state.isOpen[idx]
                  })}
                >{this.state.isOpen[idx] ? <CaretUpFill width="16px" /> : <CaretDownFill width="16px" />}</button>
              </div>
              <div
                className={cn({
                  [styles.panelRadiogroupDrawer]: true,
                  [styles.panelRadiogroupDrawerOpen]: this.state.isOpen[idx]
                })}
              >
                {validElements.map((elem, idx) => {
                  const richHtml = elem.html.replace(/\{[\w]+\}/g, (match, offset, str) => {
                    const variable = match.replace(/[\{\}]/g, "");
                    return typeof valuesHash[variable] === 'string' ? valuesHash[variable] : (valuesHash[variable].length ? valuesHash[variable].join(", ") : "");
                  });;

                  return (
                    <span key={idx} className={styles.item} dangerouslySetInnerHTML={{__html: richHtml}} />
                  );
                })}
              </div>
            </div>
          )
        })}
      </div>
    );
  }
}

Survey.Serializer.addClass(
  "radiogroup-drawer",
  [
    { name: "choices" }
  ],
  function() {
    return new RadiogroupDrawerModel("");
  },
  "question"
);

Survey.ReactQuestionFactory.Instance.registerQuestion("radiogroup-drawer", props => {
  return React.createElement(RadiogroupDrawer, props);
});