import React from "react";
import { Model as SurveyModel, Survey } from "survey-react";
import showdown from 'showdown';

import surveyStyleClasses from "styles/surveyClasses";

// API, data, & utils
import definition from './definition.json';
import { SURVEY_PAGES } from 'components/milestones/milestoneOmega/vars';
import { createPrintableSurvey } from 'lib/print/printUtils';
import * as radioAlert from 'components/milestones/milestoneOmega/radioAlert';

// Custom SurveyJS Classes
export { Select } from "../../components/survey-widgets/Select";
export { Carousel } from "../../components/survey-widgets/Carousel";
export { CarouselText } from "../../components/survey-widgets/CarouselText";
export { CarouselImage } from "../../components/survey-widgets/CarouselImage";
export { Date } from "../../components/survey-widgets/Date";
export { Cards } from "../../components/survey-widgets/Cards";
export { FormattedNumber } from "../../components/survey-widgets/FormattedNumber";
export { RadiogroupDrawer } from "../../components/survey-widgets/RadiogroupDrawer";
export { Tabs } from "../../components/survey-widgets/Tabs";
export { FAQ } from "../../components/survey-widgets/FAQ";
export { SurveyPrintButton } from '../../components/survey-widgets/SurveyPrintButton';
export { SavingYourWork } from '../../components/survey-widgets/SavingYourWork';

//this is "renderCollector"
const MilestoneOmegaSurvey = ({
  data,
  currentPage,
  completeHandler,
  pageChangeHandler,
  valueChangeHandler
}) => {

  const mOmegaModel = new SurveyModel(definition);
  if (data) {
    mOmegaModel.data = data;
  }

  const converter = new showdown.Converter();
  mOmegaModel.onTextMarkdown.add(function (survey, options) {
    //convert the markdown text to html
    let str = converter.makeHtml(options.text);
    //remove root paragraphs <p></p>
    str = str.substring(3);
    str = str.substring(0, str.length - 4);
    //set html
    options.html = str;
  });

  mOmegaModel.onAfterRenderPage.add((survey, options) => {
    if (options.page.name === 'saving_page_7') {
      createPrintableSurvey(survey, SURVEY_PAGES.PRINTABLE, options.htmlElement)
      radioAlert.create(options.htmlElement)
    }
  });

  const onSurveyValueChange = (survey, options) => {
    if (options.name === "saving_p3_q") {
      if (options.value === 'no_dontHave') {
        radioAlert.reveal(document);
      } else if (options.oldValue === 'no_dontHave') {
        radioAlert.hide(document);
      }
    }
    valueChangeHandler(options.question.name, options.value);
  }

  const onSurveyPageChange = (survey, options) => {
    if (options.newCurrentPage && options.newCurrentPage.name) {
      pageChangeHandler(options.newCurrentPage.name)
    }
  }

  return <Survey
    model={mOmegaModel}
    onComplete={completeHandler}
    focusFirstQuestionAutomatic={false}
    onValueChanging={onSurveyValueChange}
    css={surveyStyleClasses}
    currentPage={currentPage}
    onCurrentPageChanged={onSurveyPageChange}
  />;
}

export default MilestoneOmegaSurvey;
