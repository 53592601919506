import React from "react";
import * as Survey from "survey-react";
import NumberFormat from 'react-number-format';

export class FormattedNumberModel extends Survey.Question {
  getType() {
    return "formatted-number";
  }

  validateValueCallback() {
    if (!this.isRequired && !this.questionValue) {
      return false;
    }
    /* More scalable approach: use the presence of a placeholder to turn on validation.
       # symbols in the placeholder translate to \d in the regex expression. */
    if (this.isRequired && this.placeHolder !== undefined) {
      const re = new RegExp("^\\d{" + (this.placeHolder.split('#').length - 1).toString() + "}$");
      if (!re.test(this.questionValue)) {
        return new Survey.CustomError(this.validationErrorText ? this.validationErrorText : 'Please enter a number with the correct format.', this.survey);
      }
    }
  }
}

export class FormattedNumber extends Survey.SurveyElementBase {
  get question() {
    return this.props.question;
  }
  render() {
    if (!this.question) return null;
    var cssClasses = this.question.cssClasses;

    return (
      <div className={cssClasses.root}>
        <NumberFormat
          format={this.question.placeHolder ? this.question.placeHolder : undefined}
          placeholder={this.question.placeHolder ? this.question.placeHolder : undefined}
          thousandSeparator={this.question.thousandSeparator ? this.question.thousandSeparator : undefined}
          prefix={this.question.prefix ? this.question.prefix : undefined}
          defaultValue={this.question.value}
          mask="_"
          allowNegative={false}
          allowLeadingZeros={true}
          isNumericString={true}
          className={`form-control form-control-default`}
          onValueChange={values => {this.question.value = values.value}}
        />
      </div>
    );
  }
}

Survey.Serializer.addClass(
  "formatted-number",
  [
    { name: "placeHolder" },
    { name: "validationErrorText" },
    { name: "thousandSeparator" },
    { name: "prefix" }
  ],
  function() {
    return new FormattedNumberModel("");
  },
  "question"
);

Survey.ReactQuestionFactory.Instance.registerQuestion("formatted-number", props => {
  return React.createElement(FormattedNumber, props);
});