import $ from 'jquery';
import { useEffect, useState } from "react";
import { renderToString } from 'react-dom/server';
import { Link } from "react-router-dom";
import * as Survey from "survey-react";
import "survey-react/survey.css";
import showdown from 'showdown';
import { Container, Modal, Button } from 'react-bootstrap';
import { pathOr } from 'ramda';

// API
import { getMilestone, setMilestoneValue, apiRequest } from 'lib/API';

import Loading from 'components/Loading';
import Message from 'components/Message';

import definition from './definition.json';
import surveyClasses from "../../styles/surveyClasses";

import { dateInPast } from 'lib/time';
import { joinWithAnd, firstLetterUpper, thousandsSeparator, snakeToText } from 'lib/format';
import { statesDict } from 'lib/miscellaneous';
import { pop_size_dict, school_types_dict, study_options_dict, special_mission_dict, sports_dict, matterMostDict } from 'lib/m8_dicts';
import { SURVEY_PAGES } from 'components/milestones/milestone12/vars';
import { createPrintableSurvey } from 'lib/print/printUtils';
import { iframeContentId, createPrintableIframe } from 'lib/print/iframeUtils';
import createPrintableSchoolTable from "lib/print/createPrintableSchoolTable";

// Custom Components
export { Select } from "../../components/survey-widgets/Select";
export { Carousel } from "../../components/survey-widgets/Carousel";
export { Date } from "../../components/survey-widgets/Date";
export { Cards } from "../../components/survey-widgets/Cards";
export { FormattedNumber } from "../../components/survey-widgets/FormattedNumber";
export { AutocompleteList } from "../../components/survey-widgets/AutocompleteList";
export { AutocompleteFoS } from "../../components/survey-widgets/AutocompleteFoS";
export { SchoolBank } from "../../components/survey-widgets/SchoolBank";
export { Recommendations } from "../../components/survey-widgets/Recommendations";
export { Accordion } from "../../components/survey-widgets/Accordion";
export { SurveyPrintButton } from '../../components/survey-widgets/SurveyPrintButton';

function alwaysArray(value) {
  return Array.isArray(value) ? value : [value];
}

const renderCollector = (definition, milestone, data, currentPage, setLoading, setError, setSuccess, setModalOpen) => {
  let model = new Survey.Model(definition);

  var converter = new showdown.Converter();
  model.onTextMarkdown.add(function (survey, options) {
    //convert the markdown text to html
    var str = converter.makeHtml(options.text);
    //remove root paragraphs <p></p>
    str = str.substring(3);
    str = str.substring(0, str.length - 4);
    //set html
    options.html = str;
  });

  model.onAfterRenderPage.add((survey, options) => {
    if (options.page.name === 'institutions_search_page') {
      createPrintableSurvey(survey, SURVEY_PAGES.PRINTABLE, options.htmlElement)
    }
  });

  const onSurveyComplete = (survey, options) => {
    setLoading(true);
    const data = {
      publicColleges: 'california_public_schools_considered' in survey.valuesHash ? survey.valuesHash['california_public_schools_considered'] : [],
      privateColleges: 'private_schools_considered' in survey.valuesHash ? survey.valuesHash['private_schools_considered'] : [],
      standoutAdvantages: [survey.valuesHash['standout_advantages_a'], survey.valuesHash['standout_advantages_b']],
      standoutDisadvantages: [survey.valuesHash['standout_disadvantages_a'], survey.valuesHash['standout_disadvantages_b']]
    }
    apiRequest('post', '/milestone', {
      data: {
        milestone: milestone,
        key: 'submission'
      }
    })
      .then(response => {
        setModalOpen(true);
        setSuccess(true);
        setLoading(false);
      })
      .catch(error => {
        let message = 'Unknown Error';
        try {
          message = error.response.data.error_message;
        } catch {
        }
        setError(message);
        setLoading(false);
      });
  }

  const onSurveyValueChange = (survey, options) => {
    if (SURVEY_PAGES.PRINTABLE.includes(survey.activePage.name)) {
      const updatedQuestion = $(`#${iframeContentId}`).find(`#${options.question.name}-print`);
      if (updatedQuestion.length > 0 && options.question.getType() === 'school-bank') {
        $(updatedQuestion).replaceWith(renderToString(<div className={'question-content'}
          dangerouslySetInnerHTML={{
            __html: createPrintableSchoolTable(
              options.question.value,
              survey.valuesHash,
              options.question.fullTitle,
              options.question.id)
          }} key={options.question.id} />))
        $(`#${options.question.name}-print`).ready(() => {
          createPrintableIframe();
        })
      }
    }
    if (options.question.oldValue != options.value) {
      setMilestoneValue(options.question.name, options.value, milestone);
    }
  }
  function onSurveyPageChange(survey, options) {
    if (options.newCurrentPage && options.newCurrentPage.name) {
      setMilestoneValue("current_page", options.newCurrentPage.name, milestone)
    }
  }

  if (data) {
    model.data = data;
  }

  return (
    <Container fluid="md" className="survey-container survey-title-blue">
      <Survey.Survey
        model={model}
        onComplete={onSurveyComplete}
        onValueChanging={onSurveyValueChange}
        css={surveyClasses}
        currentPage={currentPage}
        onCurrentPageChanged={onSurveyPageChange}
      />
    </Container>
  );
}

const Milestone12 = ({ preferredName, schoolsList = false }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError]     = useState("");
  const [success, setSuccess] = useState(false);
  const [completed, setCompleted] = useState('');
  const [data, setData] = useState(false);
  const [currentPage, setCurrentPage] = useState(false);
  const [modalOpen, setModalOpen] = useState(true);

  const milestone = '12';

  useEffect(() => {
    getMilestone(milestone).then(({m5_data, m6_data, m7_data, m8_data, user_gpa, user_ag_number, user_aggpa, match_data, enrollment_school, current_server_time, ...responses}) => {
      let jsonResponse = {};
      let keys = Object.keys(responses);
      for (let key of keys) {
        jsonResponse[key] = responses[key].value;
        if (key === 'submission' && responses[key].timestamp) {
          const dateStr = String(responses[key].timestamp);
          const completedDate = dateStr.slice(4,6)+'-'+dateStr.slice(6,8)+'-'+dateStr.slice(0,4);
          jsonResponse['completed'] = completedDate;
          setCompleted(completedDate);
        }
      }
      const {
        submission,
        current_page,
        ...surveyData } = jsonResponse;
      if (current_page && !schoolsList) {
        setCurrentPage(current_page);
      }
      if (schoolsList) {
        setCurrentPage('institutions_search_page')
      }
      const isClosed = dateInPast(new Date(2022, 9, 30), current_server_time) ? 'yes' : 'no';
      if ((submission === true || submission === 'true' || isClosed === 'yes') && !schoolsList) {
        setCurrentPage('presentation_page');
      }

      const { completed_m5, good_at: goodAt } = m5_data;
      const { completed_m6, p1q10r1, p1q10r2 } = m6_data;
      const {
        completed_m7,
        options_considered: optionsC,
        private_schools_considered: private_sc,
        california_public_schools_considered: public_sc
      } = m7_data;
      const completed_m8 = pathOr(false, ['submission', 'value'], m8_data);
      const four_year_schools = pathOr([], ['four_year_school_bank', 'value'], m8_data).map(s => s.INSTNM);
      const two_year_schools = pathOr([], ['two_year_school_bank', 'value'], m8_data).map(s => s.INSTNM);
      const training_schools = pathOr([], ['training_school_bank', 'value'], m8_data).map(s => s.INSTNM);
      const school_names = four_year_schools.concat(two_year_schools, training_schools);
      const school_names_str_and = joinWithAnd(school_names);

      const most = p1q10r1 && 'value' in p1q10r1 ? p1q10r1.value : '';
      const secondMost = p1q10r2 && 'value' in p1q10r2 ? p1q10r2.value : '';
      const options_considered = optionsC && 'value' in optionsC ? optionsC.value : [];
      const schools_of_interest_private = private_sc && 'value' in private_sc ? private_sc.value : [];
      const schools_of_interest_public = public_sc && 'value' in public_sc ? public_sc.value : [];
      const schools_of_interest = schools_of_interest_private.concat(schools_of_interest_public);
      const good_at = goodAt && 'value' in goodAt ? goodAt.value : {};
      const goodAtKeys = ['first', 'second', 'third'];
      const goodAtStr = goodAtKeys.filter(key => key in good_at).reduce((acc, key, idx, arr) => {
        if (arr.length === 1 || idx === 0) return good_at[key];
        if (idx === arr.length -1) return acc + ' and ' + good_at[key];
        return acc + ', ' + good_at[key];
      }, '');

      Object.keys(m8_data).forEach((key) => {
        if (!(key in surveyData)) {
          surveyData[key] = m8_data[key].value;
        }
        if (key.startsWith('care_about_')) {
          surveyData[`m8_${key}`] = firstLetterUpper(m8_data[key].value);
          return;
        }
        surveyData[`m8_${key}`] = m8_data[key].value;
      });

      surveyData['m5_good_at'] = goodAtStr;
      surveyData['school_bank_names'] = school_names_str_and;
      surveyData['completed_m5'] = completed_m5 ? 'yes' : 'no';
      surveyData['completed_m6'] = completed_m6 ? 'yes' : 'no';
      surveyData['completed_m7'] = completed_m7 ? 'yes' : 'no';
      surveyData['completed_m8'] = completed_m8 ? 'yes' : 'no';
      surveyData['m7_selected_pathways'] = options_considered.join(', ');;
      surveyData['m7_schools_of_interest'] = schools_of_interest.join(', ');
      surveyData['m7_show_schools_of_interest'] = completed_m7 && surveyData['m7_schools_of_interest'].length ? 'yes' : 'no';
      surveyData['march_most_important'] = `${matterMostDict[most]} and ${matterMostDict[secondMost]}`;
      surveyData['school_dependent_year'] = enrollment_school === 'Fremont' ? 'third quarter junior year' : 'first semester junior year';
      surveyData['user_gpa'] = user_gpa;
      surveyData['user_ag_number'] = user_ag_number;
      surveyData['user_aggpa'] = user_aggpa;
      surveyData['m8_fos'] = joinWithAnd(alwaysArray(pathOr('', ['fos', 'value'], m8_data)));
      surveyData['m8_household_income'] = surveyData.m8_household_income ? `$${thousandsSeparator(surveyData.m8_household_income)}` : '';
      surveyData['m8_user_efc'] = surveyData.m8_user_efc ? `$${thousandsSeparator(surveyData.m8_user_efc)}` : '';
      surveyData['m8_user_efa'] = surveyData.m8_user_efa ? `$${thousandsSeparator(surveyData.m8_user_efa)}` : '';
      surveyData['m8_care_about_study_options'] = surveyData.m8_care_about_study_options === 'Yes'
        ? joinWithAnd(alwaysArray(pathOr([], ['study_options_preference', 'value'], m8_data)).map(k => study_options_dict[k]))
        : surveyData.m8_care_about_study_options;
      surveyData['m8_care_about_population_size'] = surveyData.m8_care_about_population_size === 'Yes'
        ? joinWithAnd(alwaysArray(pathOr([], ['population_size_preference', 'value'], m8_data)).map(k => pop_size_dict[k]))
        : surveyData.m8_care_about_population_size;
      surveyData['m8_care_about_athletic_programs'] = surveyData.m8_care_about_athletic_programs === 'Yes'
        ? joinWithAnd(alwaysArray(pathOr([], ['athletic_programs_preference', 'value'], m8_data)).map(k => sports_dict[k]))
        : surveyData.m8_care_about_athletic_programs;
      surveyData['m8_care_about_campus_locale'] = surveyData.m8_care_about_campus_locale === 'Yes'
        ? joinWithAnd(alwaysArray(pathOr([], ['campus_locale_preference', 'value'], m8_data)).map(k => firstLetterUpper(k)))
        : surveyData.m8_care_about_campus_locale;
      surveyData['m8_care_about_underrepresented_communities'] = surveyData.m8_care_about_underrepresented_communities === 'Yes'
        ? joinWithAnd(alwaysArray(pathOr([], ['demographics_preference', 'value'], m8_data)).map(k => snakeToText(k)))
        : surveyData.m8_care_about_underrepresented_communities;
      surveyData['m8_care_about_religious_affiliation'] = surveyData.m8_care_about_religious_affiliation === 'Yes'
        ? joinWithAnd(alwaysArray(pathOr([], ['religious_affiliation_preference', 'value'], m8_data)))
        : surveyData.m8_care_about_religious_affiliation;
      surveyData['m8_care_about_special_mission'] = surveyData.m8_care_about_special_mission === 'Yes'
        ? special_mission_dict[pathOr('', ['special_mission_preference', 'value'], m8_data)]
        : surveyData.m8_care_about_special_mission;
      surveyData['m8_care_about_distance_from_home'] = surveyData.m8_care_about_distance_from_home === 'Yes'
        ? snakeToText(pathOr('', ['distance_from_home_preference', 'value'], m8_data))
        : surveyData.m8_care_about_distance_from_home;
      surveyData['m8_care_about_distance_from_home'] = pathOr('', ['care_about_distance_from_home', 'value'], m8_data) === 'yes' && surveyData.m8_care_about_distance_from_home === 'In another state'
        ? `${surveyData['m8_care_about_distance_from_home']}: ${statesDict[pathOr('', ['distance_from_home_preference_state', 'value'], m8_data)]}`
        : surveyData.m8_care_about_distance_from_home;
      surveyData['m8_institution_type'] = joinWithAnd(alwaysArray(pathOr([], ['institution_type', 'value'], m8_data)).map(k => school_types_dict[k]));

      setData(surveyData);
      setLoading(false);
    }).catch(error => {
      console.log(error);
      setError("Unable to load your responses.");
      setLoading(false);
    });
  }, []);

  // NOTE: PLEASE UPDATE THE DATE INSIDE THE PROMISE ALSO
  const milestoneClosed = dateInPast(new Date(2022, 9, 30), data ? data.current_server_time : false);

  if (loading) {
    return (
      <Loading/>
    );
  } else if (error !== "") {
    return (
      <Message
        title={
          <>
            <h4>Sorry, we encountered an error with your submission:</h4>
            <p>"{error}"</p>
          </>
        }
        description={
          <h5>Please contact info@pathways2.college and provide the error message above.</h5>
        }
      />
    );
  } else if (modalOpen && (completed || milestoneClosed)) {
    return (
      <>
        <ClosedModal
          show={modalOpen && !schoolsList}
          onHide={() => setModalOpen(false)}
          completed={completed}
          closed={milestoneClosed}
        />
        {renderCollector(definition, milestone, data, currentPage, setLoading, setError, setSuccess, setModalOpen)}
      </>
    );
  } else if (success) {
    return (
      <Message
        title={
          <>
            <h4>You’ve submitted Milestone 12!</h4>
            <h5>Great work! You should receive your $40 CFC shortly after submitting.</h5>
            <h5 style={{textAlign: 'center'}}>Visit your <Link to={`/dashboard`}>Will Apply list here</Link>. We encourage you to print this list, or print to PDF and save it on your computer.</h5>
          </>
        }
        description={
          <span className='text-left'>
            <p style={{textAlign: 'center'}}>P2C Bot will text and email you about your next Milestone on the first Monday of the month: October 3rd.</p>
          </span>
        }
      />
    );
  } else {
    return(renderCollector(definition, milestone, data, currentPage, setLoading, setError, setSuccess, setModalOpen));
  }
}

function ClosedModal({completed, closed, ...props}) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="modal-title">
          Information
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {completed ? (
          <p>You completed this milestone on <b>{completed}</b>. You should receive CFC payment within 7 days of completion.</p>
        ) : (
          <p>This Milestone is now closed and can no longer be submitted for CFC and Savings Fund Payment.</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
          <Button onClick={props.onHide}>Close</Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default Milestone12;