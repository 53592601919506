import React from "react";
import * as Survey from "survey-react";
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { Navigation, Pagination, A11y } from 'swiper';
import { CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';
import cn from "classnames";

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/modules/navigation/navigation.scss';
import 'swiper/modules/pagination/pagination.scss';
import styles from 'styles/accordion.module.scss';

export class CarouselModel extends Survey.Question {
  getType() {
    return "carousel-text";
  }

  validateValueCallback() {
    if (!this.mustRead) {
      return false;
    }
    if (this.mustRead && (!this.questionValue || this.questionValue < (this.slides.length - 1))) {
      return new Survey.CustomError(this.validationErrorText ? this.validationErrorText : 'Please read all the slides.', this.survey);
    }
    return false;
  }
}

export class CarouselText extends Survey.SurveyElementBase {
  get question() {
    return this.props.question;
  }
  render() {
    if (!this.question) return null;
    var cssClasses = this.question.cssClasses;
    const imageUrl = this.question.image;
    return (
      <div className={cssClasses.root}>
        <div className="carousel-text">
          <div className="carousel-text__image">
            <img src={`/images/milestones/${imageUrl}`} style={{maxWidth: '100%', maxHeight: '600px', left: '60px'}} />
          </div>
          <div className="carousel-text__carousel">
            <Swiper
              modules={[Navigation, Pagination, A11y]}
              navigation={true}
              pagination={true}
              a11y={true}
              slidesPerView={1}
              initialSlide={this.question.value ? this.question.value : 0}
              onSlideChange={(swiper) => {
                if (!this.question.value || this.question.value < swiper.activeIndex) {
                  this.question.value = swiper.activeIndex;
                }
              }}
              onSwiper={(swiper) => console.log(swiper)}
            >
              {this.question.slides.map((item, index) => {
                return (
                  <SwiperSlide key={item.title} className="carousel-slide">
                    <div className="">
                      <h2 className="carousel-text__title">{item.month}</h2>
                      {
                        item.items.map((element, idx) => (
                          <div style={{paddingTop: idx !== 0 ? 16 : 0}} className="carousel-text__content-wrapper">
                            <h1 className="carousel-text__content-title" dangerouslySetInnerHTML={{__html: `${idx + 1}. ${element.title}`}} />
                            {typeof element.explanation !== 'undefined' ? (
                              element.drawer ? <Expander item={element} /> : <div className="carousel-text__content" dangerouslySetInnerHTML={{__html: element.explanation}} />) : null}
                          </div>
                        ))
                      }
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
          
        </div>
      </div>
    );
  }
}

function Expander({ item }) {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <>
      <button
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        className={cn({
          [styles.accordion]: true,
          [styles.active]: isOpen
        })}
      >{item.explanationTitle ? item.explanationTitle : 'Click to expand'}{isOpen ? <CaretUpFill width="16px" /> : <CaretDownFill width="16px" />}</button>
      <div
        className={cn({
          [styles.panel]: true,
          [styles.panelOpen]: isOpen
        })}
      >
        <div className="carousel-slide__explanation" dangerouslySetInnerHTML={{__html: item.explanation}} />
        <div className={styles.closeButtonContainer}>
          <button
            className={styles.closeButton}
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >Close</button>
        </div>
      </div>
    </>
  )
}

Survey.Serializer.addClass(
  "carousel-text",
  [
    { name: "slides" },
    { name: "mustRead" },
    { name: "image" }
  ],
  function() {
    return new CarouselModel("");
  },
  "question"
);

Survey.ReactQuestionFactory.Instance.registerQuestion("carousel-text", props => {
  return React.createElement(CarouselText, props);
});