import React from "react";
import * as Survey from "survey-react";
import cn from "classnames";
import styles from 'styles/autocomplete-list.module.scss';
import Downshift from 'downshift';
import { Trash } from 'react-bootstrap-icons';

export class AutocompleteListModel extends Survey.Question {
  getType() {
    return "autocomplete-list";
  }
}

export class AutocompleteList extends Survey.SurveyElementBase {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.state = {
      isOpen: false,
    };
  }
  get question() {
    return this.props.question;
  }
  render() {
    if (!this.question) return null;
    var cssClasses = this.question.cssClasses;
    const removeRow = (name) => {
      const newValue = this.question.value.filter(row => row !== name);
      this.question.value = newValue;
    };
    return (
      <div className={cssClasses.root}>
        <div className={styles.table}>
          {this.question.value ? this.question.value.map(item => (
            <div className={styles.row}>
              <div>{item}</div>
              <div className={styles.trashTd}>
                <button
                  onClick={() => removeRow(item)}
                  className={styles.trashButton}><Trash /></button>
              </div>
            </div>
          )):null}
        </div>
        <Downshift
          onChange={(selection, {clearSelection}) => {
            if (selection) {
              this.question.value = this.question.value ? this.question.value.concat(selection) : [selection];
            }
            clearSelection();
            this.inputRef.current.blur();
          }}
          itemToString={(item) => (item ? item : '')}
        >
          {({
            getItemProps,
            getMenuProps,
            inputValue,
            getInputProps,
            selectedItem,
            getRootProps,
          }) => (
            <div>
              <div
                {...getRootProps({}, {suppressRefError: true})}
              >
                <input
                  ref={this.inputRef}
                  {...getInputProps()} className={cn('form-control', styles.input)}
                  onFocus={() => this.setState({isOpen: true})}
                  onBlur={() => this.setState({isOpen: false})}
                />
              </div>
              {this.state.isOpen ? <ul {...getMenuProps()} className={styles.menuStyles}>
                {this.question.choices.filter(item => !inputValue || item.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
                  .filter(item => !this.question.value || !this.question.value.find(value => value === item))
                  .map((item, index) => (
                    <li
                      {...getItemProps({
                        key: item,
                        index,
                        item,
                        className:"select-option",
                        style: {
                          fontWeight: selectedItem === item ? 'bold' : 'normal'
                        },
                      })}
                    >
                      {item}
                    </li>
                  ))}
              </ul>: null}
            </div>
          )}
        </Downshift>        
      </div>
    );
  }
}

Survey.Serializer.addClass(
  "autocomplete-list",
  [
    { name: "choices" }
  ],
  function() {
    return new AutocompleteListModel("");
  },
  "question"
);

Survey.ReactQuestionFactory.Instance.registerQuestion("autocomplete-list", props => {
  return React.createElement(AutocompleteList, props);
});