import { useEffect, useState, useRef } from 'react';
import { Link, useParams, useHistory } from "react-router-dom";
import TimeMe from "timeme.js";

// API
import { getProfile, getMilestone, postEvent } from 'lib/API';

// utils
import cn from 'classnames';
import { pathOr } from 'ramda';
import { dateInPast } from 'lib/time';

// Routes & Components
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';
import { CheckCircleFill } from 'react-bootstrap-icons';
import Loading from 'components/Loading';
import Message from 'components/Message';
import Profile from 'components/forms/Profile';
import WillApply from 'components/WillApply';
import { MILESTONE_TITLE as OMEGA_TITLE, MILESTONE_OMEGA_NUMBER as OMEGA_NUMBER } from './milestones/milestoneOmega/vars';

// Styles
import styles from 'styles/dashboard.module.scss';
import timelineStyles from 'styles/timeline.module.scss';
import vars from 'styles/vars.module.scss';

/*-- tab routing strings that don't need to rerender every time Dashboard does & don't rely on Dashboard state --*/
export const TABS = {
  DASHBOARD: {
    eventKey: "home", 
    title: "Dashboard",
  },
  WILL_APPLY: {
    eventKey: "will-apply",
    title: "Will Apply",
  },
  PAST_MILESTONES: {
    eventKey: "past-milestones",
    title: "Past Milestones",
  },
  PROFILE: {
    eventKey: "profile",
    title: "Profile",
  }
}

/*-- past milestone logic that doesn't need to rerender every time Dashboard does & doesn't rely on Dashboard state --*/
// Format: YYYY, Month -1, Day. Represents the last day the milestone is active.
const closeDatesDict = { '8': [2022, 4, 30], '10': [2022, 6, 27], '11': [2022, 7, 30], '12': [2022, 8, 30], [OMEGA_NUMBER]: [2022, 10, 31] };
const milestonesTitlesDict = {
  '8': 'College Search', 
  '10': 'Financial Aid Knowledge Base', 
  '11': 'Application Preparation',
  '12': 'College Search Part 2',
  [OMEGA_NUMBER]: OMEGA_TITLE
};

export default function Dashboard({ preferredName, user }) {
  const [totalCfc, setTotalCfc] = useState(null);
  const [totalSavings, setTotalSavings] = useState(null);
  const [totalMilestones, setTotalMilestones] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const [pathwaysM6, setPathwaysM6] = useState([]);
  const [answeredDict, setAnsweredDict] = useState({});
  const [currentMilestone, setCurrentMilestone] = useState(null);

  const [m12_data, setM12_data] = useState(null);

  /*-- tab routing with URLs --*/
  const { activeTab } = useParams();
  const { push: pushToHistory } = useHistory();
  //redirect navigation from "dashboard/" to "dashboard/home"
  useEffect(() => {
    if (!activeTab && !loading && !error) {
        pushToHistory(`/dashboard/${TABS.DASHBOARD.eventKey}`);
      }
  }, [activeTab, loading, error, pushToHistory]);

  // Use a ref to access the current state of answeredDict
  const answeredDictRef = useRef(answeredDict);
  answeredDictRef.current = answeredDict;

  // Milestones to show in "Past milestones tab"
  const pastMilestones = ['8', '10', '11', '12', OMEGA_NUMBER].filter(milestone => {
    if (milestone === '8') {
      return !(Number(currentMilestone) === 12 || answeredDict['12'] || dateInPast(new Date(...closeDatesDict['12']), false));
    }
    return answeredDict[milestone] || dateInPast(new Date(...closeDatesDict[milestone]), false);
  });

  useEffect(() => {
    const getMilestoneData = (mNumber) => {
      getMilestone(mNumber).then((responses) => {
        if ('submission' in responses && 'value' in responses['submission'] && responses['submission'].value) {
          setAnsweredDict({...answeredDictRef.current, [mNumber]: true});
        }
        if (mNumber === '12') {
          setM12_data(responses);
        }
      }).catch(() => {
        console.error(`Error loading milestone ${mNumber}`);
      });
    }

    setLoading(true);
    getProfile().then((response) => {
      setTotalCfc(response.total_cfc);
      setTotalSavings(response.total_savings);
      setTotalMilestones(response.total_milestones);
      if (response.completed_milestone_1) {
        setCurrentMilestone(response.current_milestone);
      } else {
        setCurrentMilestone(1);
      }
      setLoading(false);
    }).catch(() => {
      setError("Unable to load your information.");
      setLoading(false);
    });
    getMilestone('6').then(({m5_data, enrollment_school, current_server_time, ...responses}) => {
      const pathways_selection = [
        'learn_more_about_4_year_colleges',
        'learn_more_about_2_year_colleges',
        'learn_more_about_training_programs'
      ].filter(k => (
        'pathways_selection' in responses && 'value' in responses['pathways_selection'] && responses['pathways_selection'].value.includes(k)
      ));
      setPathwaysM6(pathways_selection);
    }).catch(() => {
      console.error('Error loading M6');
    });
    ['7', '8', '10', '11', '12', '13'].forEach(mNumber => {
      getMilestoneData(mNumber);
    });
  }, []);

  useEffect(() => {
    TimeMe.stopTimer();
    TimeMe.setCurrentPageName("DASHBOARD");
    TimeMe.startTimer();
  }, []);


  const handleActiveTabChange = (eventKey) => {
    if (activeTab !== eventKey) {
      pushToHistory(`/dashboard/${eventKey}`);
    }
  }

  const trackSelectTab = (tab) => {
    postEvent(`DASHBOARD_TAB_CLICK_${tab.toUpperCase()}`);
  }

  if (error !== "") {
    return (
      <Message
        title={
          <>
            <h4>Sorry, we encountered an error:</h4>
            <p>"{error}"</p>
          </>
        }
        description={
          <h5>Please contact info@pathways2.college and provide the error message above.</h5>
        }
      />
    );
  } else if (loading || !m12_data) {
    return (
      <Loading />
    );
  } else {
    return (        
      <Container>
        <Row>
          <Col>   
            <div className="dashboard-item text-center">
              <div className="light light-small-padding">
                <h3 className='dashboard-title'>Welcome to Pathways2College, {preferredName}!</h3>
              </div>
              <div className={cn("dark", timelineStyles.pagePadding)}>
                <Tabs
                  activeKey={activeTab}
                  defaultActiveKey={TABS.DASHBOARD.eventKey}
                  onSelect={(k) => {
                    trackSelectTab(k);
                    handleActiveTabChange(k);
                  }}
                  id="take-home-tabs"
                  className="mb-3">
                    <Tab style={{position: 'relative'}} className={styles.dashboardTabContent} eventKey={TABS.DASHBOARD.eventKey} title={TABS.DASHBOARD.title}>
                      <p>Your next milestone is:</p>
                      <h4>
                        <Link to={`/milestone${currentMilestone}`}>
                          Milestone {currentMilestone}
                        </Link>
                      </h4>
                      <div className={styles.cardsContainer}>
                        <div className={styles.card}>
                          <span className={styles.cardDescription}>
                            You've completed
                          </span>
                          <span className={styles.cardValue}>{totalMilestones}</span>
                          <span className={styles.cardDescription}>
                            Milestone{totalMilestones !== 1 ? 's' : ''}
                          </span>
                        </div>
                        <div className={styles.card}>
                          <span className={styles.cardDescription}>
                            You've earned
                          </span>
                          <span className={styles.cardValue}>${totalCfc}</span>
                          <span className={styles.cardDescription}>
                            in Cash-for-Completion
                          </span>
                        </div>
                        <div className={styles.card}>
                          <span className={styles.cardDescription}>
                            You've earned
                          </span>
                          <span className={styles.cardValue}>${totalSavings}</span>
                          <span className={styles.cardDescription}>
                            in Savings Funds
                          </span>
                        </div>
                      </div>
                      <div className={styles.bonusMessage}>
                        <b>New Bonus Opportunity:</b> Students receiving a C or better in all courses will earn bonus funds at the end of their term! Keep those grades up throughout the school year and earn more money for your hard work!
                      </div>
                    </Tab>
                    {answeredDict['12'] ? (
                      <Tab style={{position: 'relative'}} className={styles.dashboardTabContent} eventKey={TABS.WILL_APPLY.eventKey} title={TABS.WILL_APPLY.title}>
                        <WillApply m12_data={m12_data} />
                      </Tab>
                    ):null}
                    <Tab style={{position: 'relative'}} className={styles.dashboardTabContent} eventKey={TABS.PAST_MILESTONES.eventKey} title={TABS.PAST_MILESTONES.title}>
                      <h4 style={{color: '#b5160b', marginTop: 21}}>
                        Milestones
                      </h4>
                      <div className={styles.cardsContainerFour}>
                        {pastMilestones.map(m => {
                          return (
                            <Link to={`/milestone${m}`} key={`milestone-${m}`}>
                              <div className={styles.card}>
                                <h2 style={{fontWeight: 700, fontSize: 22}}>Milestone {m}</h2>
                                <span className={styles.cardDescription}>
                                  {milestonesTitlesDict[m]}
                                </span>
                                {answeredDict[m] ? (
                                  <span className={styles.successCircle}><CheckCircleFill style={{color: vars.darkGreen}} /></span>
                                ):null}
                              </div>
                            </Link>
                          )
                        })}
                      </div>
                      <h4 style={{color: '#b5160b', marginTop: 47}}>
                        Documents
                      </h4>
                        {pathwaysM6.length > 0 || answeredDict['7'] ? (
                          <div className={styles.cardsContainer}>
                            {pathwaysM6.length > 0 ? (
                              <Link className={styles.card} to={`/take-home`}>
                                <span className={styles.cardDescription}>
                                  Milestone 6 Takehome:
                                </span>
                                <h4 style={{color: '#b5160b', marginTop: 6, fontSize: 18, fontWeight: 600}}>
                                  My Timelines
                                </h4>
                              </Link>
                            ) : null }
                            {answeredDict['7'] ? (
                              <Link className={styles.card} to={`/take-home-m7`}>
                                <span className={styles.cardDescription}>
                                  Milestone 7 Takehome:
                                </span>
                                <h4 style={{color: '#b5160b', marginTop: 6, fontSize: 18, fontWeight: 600}}>
                                  California Colleges
                                </h4>
                              </Link>
                            ) : null }
                          </div>
                        ) : (
                          <div className={styles.bonusMessage}>
                            You don't have any documents yet
                          </div>
                        )
                      }
                    </Tab>
                    <Tab style={{position: 'relative'}} className={styles.dashboardTabContent} eventKey={TABS.PROFILE.eventKey} title={TABS.PROFILE.title}>
                      <Profile />
                    </Tab>
                </Tabs>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }
}