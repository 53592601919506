import React from "react";
import * as Survey from "survey-react";
import cn from "classnames";
import styles from 'styles/school-bank.module.scss';
import SchoolBankTable from "components/SchoolBankTable";
import createPrintableSchoolTable from "lib/print/createPrintableSchoolTable";

export class SchoolBankModel extends Survey.Question {
  getType() {
    return "school-bank";
  }
  validateValueCallback() {
    if (!this.isRequired && !this.questionValue) {
      return false;
    }
    if (this.questionValue && this.questionValue.length) {
      const missingPriorities = this.questionValue.filter((school) => !school.priority);
      if (missingPriorities.length) {
        return new Survey.CustomError("Please select a priority for each school.", this.survey);
      }
    }
    const surveyValues = this.surveyValue.valuesHash;
    const fourYearLength = 'four_year_school_bank' in surveyValues ? surveyValues['four_year_school_bank'].length : 0;
    const twoYearLength = 'two_year_school_bank' in surveyValues ? surveyValues['two_year_school_bank'].length : 0;
    const trainingLength = 'training_school_bank' in surveyValues ? surveyValues['training_school_bank'].length : 0;
    if (fourYearLength + twoYearLength + trainingLength < 3) {
      return new Survey.CustomError("Please select at least 3 schools in total.", this.survey);
    }
    if (this.willApply) {
      const fourYearWillApply = 'four_year_school_bank' in surveyValues
        ? surveyValues['four_year_school_bank'].filter(s => s.will_apply).length : 0;
      const twoYearWillApply = 'two_year_school_bank' in surveyValues
        ? surveyValues['two_year_school_bank'].filter(s => s.will_apply).length : 0;
      const trainingWillApply = 'training_school_bank' in surveyValues
        ? surveyValues['training_school_bank'].filter(s => s.will_apply).length : 0;
      if (fourYearWillApply + twoYearWillApply + trainingWillApply < 3) {
        return new Survey.CustomError('Please add at least 3 schools to your "Will Apply" list.', this.survey);
      }
    }
    return false;
  }

  getPrintableContent(vars) {

    const schoolList = this.surveyValue?.valuesHash[this.propertyHash.name] || vars[this.propertyHash.name] || [];

    return schoolList.length > 0
      ? <div className={'question-content'}
        dangerouslySetInnerHTML={{
          __html: createPrintableSchoolTable(schoolList, this.surveyValue.valuesHash, this.fullTitle, this.propertyHash.name)
        }} key={this.id} />
      : null
  }
}

export class SchoolBank extends Survey.SurveyElementBase {
  get question() {
    return this.props.question;
  }
  render() {
    if (!this.question) return null;
    var cssClasses = this.question.cssClasses;

    const addRow = (row) => {
      if (!this.question.value) {
        this.question.value = [row];
      } else {
        this.question.value = [...this.question.value, row];
      }
    };
    const removeRow = (name) => {
      const index = this.question.value.findIndex(row => row['INSTNM'] === name);
      if (index > -1) {
        this.question.value = this.question.value.slice(0, index).concat(this.question.value.slice(index + 1));
      }
    };
    const updateRow = (row) => {
      const index = this.question.value.findIndex(r => r['INSTNM'] === row['INSTNM']);
      if (index > -1) {
        this.question.value = this.question.value.slice(0, index).concat([row]).concat(this.question.value.slice(index + 1));
      }
    };
    const valuesHash = this.question.surveyValue?.valuesHash;
    return (
      <div className={cssClasses.root}>
        <SchoolBankTable
          rows={this.question.value ? this.question.value : []}
          addRow={addRow}
          schoolType={this.question.schoolType}
          removeRow={removeRow}
          valuesHash={valuesHash}
          updateRow={updateRow}
          preferenceMetrics={this.question.preference_metrics}
          allowCustomText={this.question.allowCustomText}
          showSortMessage={this.question.showSortMessage}
          willApply={this.question.willApply}
          showFoS={this.question.show_fos}
          milestone={this.question.milestone}
        />
      </div>
    );
  }
}

Survey.Serializer.addClass(
  "school-bank",
  [
    { name: "preference_metrics" },
    { name: "allowCustomText" },
    { name: "schoolType" },
    { name: "willApply" },
    { name: "show_fos" },
    { name: "milestone" },
    { name: "showSortMessage" }
  ],
  function() {
    return new SchoolBankModel("");
  },
  "question"
);

Survey.ReactQuestionFactory.Instance.registerQuestion("school-bank", props => {
  return React.createElement(SchoolBank, props);
});