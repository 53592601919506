import React from "react";
import { Question, SurveyElementBase, Serializer, ReactElementFactory } from "survey-react";
import PrintButton from "components/PrintButton";

const CUSTOM_TYPE = "print-button";
const CUSTOM_PROPERTIES = {
  BUTTON_TEXT: "text"
}

export class SurveyPrintButtonModel extends Question {
  getType() {
    return CUSTOM_TYPE;
  }
}

export class SurveyPrintButton extends SurveyElementBase {
  constructor(props) {
    super(props);
  }

  get buttonText() {
    return this.props.element?.[CUSTOM_PROPERTIES.BUTTON_TEXT]
  }

  render() {
    if (!this.props.element) {
      return null;
    }

    return <PrintButton
      buttonText={this.buttonText}
      containerModuleClassName={'surveyPrintButtonContainer'}
    />;
  }
}

Serializer.addClass(
  CUSTOM_TYPE,
  [{
    name: CUSTOM_PROPERTIES.BUTTON_TEXT,
    default: "Print"
  }],
  () => new SurveyPrintButtonModel(""),
  "question"
);

ReactElementFactory
  .Instance
  .registerElement(
    CUSTOM_TYPE,
    props => React.createElement(SurveyPrintButton, props)
  );