import React from "react";
import * as Survey from "survey-react";
import cn from "classnames";
import styles from 'styles/autocomplete-list.module.scss';
import Downshift from 'downshift';
import { Trash } from 'react-bootstrap-icons';
import fieldsOfStudy from 'lib/data/fieldsOfStudy.json';

export class AutocompleteFoSModel extends Survey.Question {
  getType() {
    return "autocomplete-fos";
  }
}

export class AutocompleteFoS extends Survey.SurveyElementBase {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.state = {
      isOpen: false,
    };
  }
  get question() {
    return this.props.question;
  }
  render() {
    if (!this.question) return null;
    var cssClasses = this.question.cssClasses;
    const removeRow = (name) => {
      const newValue = this.question.value.filter(row => row !== name);
      this.question.value = newValue;
    };
    const subCategories = Object.keys(fieldsOfStudy);

    return (
      <div className={cssClasses.root}>
        <div className={styles.table}>
          {this.question.value ? this.question.value.map(item => (
            <div className={styles.row}>
              <div>{item}</div>
              <div className={styles.trashTd}>
                <button
                  onClick={() => removeRow(item)}
                  className={styles.trashButton}><Trash /></button>
              </div>
            </div>
          )):null}
        </div>
        <Downshift
          onChange={(selection, {clearSelection}) => {
            if (selection) {
              this.question.value = this.question.value ? this.question.value.concat(selection) : [selection];
            }
            clearSelection();
            this.inputRef.current.blur();
          }}
          itemToString={(item) => (item ? item : '')}
        >
          {({
            getItemProps,
            getMenuProps,
            inputValue,
            getInputProps,
            selectedItem,
            getRootProps,
          }) => (
            <div>
              <div
                {...getRootProps({}, {suppressRefError: true})}
              >
                <input
                  ref={this.inputRef}
                  {...getInputProps()} className={cn('form-control', styles.input)}
                  onFocus={() => this.setState({isOpen: true})}
                  onBlur={() => this.setState({isOpen: false})}
                />
              </div>
              {this.state.isOpen ? <ul {...getMenuProps()} className={styles.menuStyles}>
                {filterValues(inputValue, this.question.value, this.question.choices, subCategories)
                  .map((item, index) => (
                    <li
                      {...getItemProps({
                        key: index,
                        index,
                        item: item.category,
                        className:"select-option",
                        style: {
                          fontWeight: selectedItem === item ? 'bold' : 'normal'
                        },
                      })}
                    >
                      {item.category}
                      {item.subcategory ? (
                        <>
                          <br />
                          <small>{item.subcategory}</small>
                        </>
                      ): null}
                    </li>
                  ))}
              </ul>: null}
            </div>
          )}
        </Downshift>        
      </div>
    );
  }
}

Survey.Serializer.addClass(
  "autocomplete-fos",
  [
    { name: "choices" }
  ],
  function() {
    return new AutocompleteFoSModel("");
  },
  "question"
);

Survey.ReactQuestionFactory.Instance.registerQuestion("autocomplete-fos", props => {
  return React.createElement(AutocompleteFoS, props);
});

function filterValues(inputValue, questionValue, choices, subCategories) {
  const inputValueLowerCase = inputValue.toLowerCase();
  let filteredValues = choices.filter(item => !inputValue || item.toLowerCase().includes(inputValueLowerCase))
    .filter(item => !questionValue || !questionValue.find(value => value === item));
  const filteredSubCategories = subCategories.filter(item => inputValue.length > 2 && item.toLowerCase().includes(inputValueLowerCase))
    .filter(item => !questionValue || !questionValue.find(value => value === fieldsOfStudy[item]))
    .map(item => ({subcategory: item, category: fieldsOfStudy[item]}))
    .filter(item => !filteredValues.find(value => value === item.category));
  filteredValues = filteredValues.map(item => ({category: item}));
  const finalResult = filteredValues.concat(filteredSubCategories);
  // Sort finalResult by category
  finalResult.sort((a, b) => {
    if (a.category < b.category) {
      return -1;
    }
    if (a.category > b.category) {
      return 1;
    }
    return 0;
  });
  return finalResult;
}