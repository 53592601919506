import { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import * as Survey from "survey-react";
import "survey-react/survey.css";
import showdown from 'showdown';
import { Container } from 'react-bootstrap';

// API
import { getMilestone, setMilestoneValue, apiRequest } from 'lib/API';

import Loading from 'components/Loading';
import Message from 'components/Message';

import definition from './definition.json';
import surveyClasses from "../../styles/surveyClasses";

import { dateInPast } from 'lib/time';

// Custom Components
export { Select } from "../../components/survey-widgets/Select";

const renderCollector = (milestone, data, currentPage, setLoading, setError, setSuccess) => {
  let model = new Survey.Model(definition);
  var converter = new showdown.Converter();
  model.onTextMarkdown.add(function (survey, options) {
    //convert the markdown text to html
    var str = converter.makeHtml(options.text);
    //remove root paragraphs <p></p>
    str = str.substring(3);
    str = str.substring(0, str.length - 4);
    //set html
    options.html = str;
  });

  const onSurveyComplete = (survey, options) => {
    setLoading(true);
    apiRequest('post', '/milestone', {
      data: {
        milestone: milestone,
        key: 'submission'
      }
    })
    .then(response => {
      setSuccess(true);
      setLoading(false);
    })
    .catch(error => {
      let message = 'Unknown Error';
      try {
        message = error.response.data.error_message;
      } catch {
      }
      setError(message);
      setLoading(false);
    });
  }

  const onSurveyValueChange = (survey, options) => {
    setMilestoneValue(options.question.name, options.value, milestone);
  }
  function onSurveyPageChange(survey, options) {
    if (options.newCurrentPage && options.newCurrentPage.name) {
      setMilestoneValue("current_page", options.newCurrentPage.name, milestone)
    }    
  }

  if (data) {
    model.data = data;
  }

  return (
    <Container fluid="md" className="survey-container">
      <Survey.Survey
        model={model}
        onComplete={onSurveyComplete}
        onValueChanging={onSurveyValueChange}
        css={surveyClasses}
        currentPage={currentPage}
        onCurrentPageChanged={onSurveyPageChange}
      />
    </Container>
  );
}

const Milestone3 = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError]     = useState("");
  const [success, setSuccess] = useState(false);
  const [data, setData] = useState(false);
  const [currentPage, setCurrentPage] = useState(false);

  const CURRENT_MILESTONE = process.env.REACT_APP_CURRENT_MILESTONE;
  const milestone = '3';

  useEffect(() => {
    getMilestone(milestone).then((responses) => {
      let jsonResponse = {};
      let keys = Object.keys(responses);
      for (let key of keys) {
        jsonResponse[key] = responses[key].value;
      }
      if (jsonResponse.submission === true || jsonResponse.submission === 'true') {
        setSuccess(true);
      }
      if (jsonResponse.current_page) {
        setCurrentPage(jsonResponse.current_page);
      }

      delete jsonResponse['submission'];
      delete jsonResponse['cuurent_page'];

      setData(jsonResponse);
      setLoading(false);
    }).catch(() => {
      setError("Unable to load your responses.");
      setLoading(false);
    });
  }, []);


  if (CURRENT_MILESTONE !== milestone) {
    return (
      <Redirect to={`/`} />
    );
  }

  // The new date arguments are fullYear (e.g. 2021), 0 based index month (i.e. 0=Jan, 1=Feb...),
  // and day of the month (e.g. 1, 2, 3... 31). We used this format so the date doesn't get
  // affected by timezone.
  const showMessage = dateInPast(new Date(2021, 11, 29));

  if (showMessage) {
    return (
      <Message
        title={
          <h4>Milestone 3 is no longer available.</h4>
        }
        subtitle="Keep your eyes peeled👀 for texts from P2C Bot🤖 on Monday, January 3, with information about Milestone 4!"
        description={
          <span className='text-left'>
            <p style={{textAlign: 'center'}}>If you have any questions, please email <a href="mailto:info@pathways2.college">info@pathways2.college</a>!</p>
          </span>
        }
      />
    );
  } else if (loading) {
    return (
      <Loading/>
    );
  } else if (error !== "") {
    return (
      <Message
        title={
          <>
            <h4>Sorry, we encountered an error with your submission:</h4>
            <p>"{error}"</p>
          </>
        }
        description={
          <h5>Please contact info@pathways2.college and provide the error message above.</h5>
        }
      />
    );
  } else if (success) {
    return (
      <Message
        title={
          <>
            <h4>You’ve completed Milestone 3! Wonderful work!</h4>
            <h5>You should receive your $20 CFC within seven days of your completion date.</h5>
          </>
        }
        description={
          <span className='text-left'>
            <p>You’ll receive your next Milestone on the first Monday of the next month: January 3rd!</p>
          </span>
        }
      />
    );
  } else {
    return(renderCollector(milestone, data, currentPage, setLoading, setError, setSuccess));
  }
}

export default Milestone3;