import { useEffect, useState } from "react";
import * as Survey from "survey-react";
import "survey-react/survey.css";
import showdown from 'showdown';
import { Container, Modal, Button } from 'react-bootstrap';

// API
import { getMilestone, setMilestoneValue, apiRequest } from 'lib/API';

import Loading from 'components/Loading';
import Message from 'components/Message';

import definition from './definition.json';
import surveyClasses from "../../styles/surveyClasses";

import { dateInPast } from 'lib/time';

// Custom Components
export { Select } from "../../components/survey-widgets/Select";
export { Carousel } from "../../components/survey-widgets/Carousel";
export { CarouselText } from "../../components/survey-widgets/CarouselText";
export { CarouselImage } from "../../components/survey-widgets/CarouselImage";
export { Date } from "../../components/survey-widgets/Date";
export { Cards } from "../../components/survey-widgets/Cards";
export { FormattedNumber } from "../../components/survey-widgets/FormattedNumber";
export { RadiogroupDrawer } from "../../components/survey-widgets/RadiogroupDrawer";

const renderCollector = (definition, milestone, data, currentPage, setLoading, setError, setSuccess, setModalOpen) => {
  let model = new Survey.Model(definition);

  var converter = new showdown.Converter();
  model.onTextMarkdown.add(function (survey, options) {
    //convert the markdown text to html
    var str = converter.makeHtml(options.text);
    //remove root paragraphs <p></p>
    str = str.substring(3);
    str = str.substring(0, str.length - 4);
    //set html
    options.html = str;
  });

  const onSurveyComplete = (survey, options) => {
    setLoading(true);
    apiRequest('post', '/milestone', {
      data: {
        milestone: milestone,
        key: 'submission'
      }
    })
    .then(response => {
      setModalOpen(true);
      setSuccess(true);
      setLoading(false);
    })
    .catch(error => {
      let message = 'Unknown Error';
      try {
        message = error.response.data.error_message;
      } catch {
      }
      setError(message);
      setLoading(false);
    });
  }

  const onSurveyValueChange = (survey, options) => {
    setMilestoneValue(options.question.name, options.value, milestone);
  }
  function onSurveyPageChange(survey, options) {
    if (options.newCurrentPage && options.newCurrentPage.name) {
      setMilestoneValue("current_page", options.newCurrentPage.name, milestone)
    }    
  }

  if (data) {
    model.data = data;
  }

  return (
    <Container fluid="md" className="survey-container survey-title-blue">
      <Survey.Survey
        model={model}
        onComplete={onSurveyComplete}
        onValueChanging={onSurveyValueChange}
        css={surveyClasses}
        currentPage={currentPage}
        onCurrentPageChanged={onSurveyPageChange}
      />
    </Container>
  );
}

const Milestone10 = ({ preferredName }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError]     = useState("");
  const [success, setSuccess] = useState(false);
  const [data, setData] = useState(false);
  const [currentPage, setCurrentPage] = useState(false);
  const [modalOpen, setModalOpen] = useState(true);
  const [completed, setCompleted] = useState('');

  const milestone = '10';

  useEffect(() => {
    getMilestone(milestone).then(({enrollment_school, current_server_time, ...responses}) => {
      let jsonResponse = {};
      let keys = Object.keys(responses);
      for (let key of keys) {
        jsonResponse[key] = responses[key].value;
        if (key === 'submission' && responses[key].timestamp) {
          const dateStr = String(responses[key].timestamp);
          const completedDate = dateStr.slice(4,6)+'-'+dateStr.slice(6,8)+'-'+dateStr.slice(0,4);
          jsonResponse['completed'] = completedDate;
          setCompleted(completedDate);
        }
      }
      const {
        submission,
        current_page,
        ...surveyData } = jsonResponse;
      if (current_page) {
        setCurrentPage(current_page);
      }
      const isClosed = dateInPast(new Date(2022, 6, 27), current_server_time) ? 'yes' : 'no';
      if (submission === true || submission === 'true' || isClosed === 'yes') {
        setCurrentPage('presentation_page');
      }

      setData({...surveyData, preferred_name: preferredName});
      setLoading(false);
    }).catch(error => {
      console.log(error);
      setError("Unable to load your responses.");
      setLoading(false);
    });
  }, []);

  // NOTE: PLEASE UPDATE THE DATE INSIDE THE PROMISE ALSO
  const milestoneClosed = dateInPast(new Date(2022, 6, 27), data ? data.current_server_time : false);

  if (loading) {
    return (
      <Loading/>
    );
  } else if (error !== "") {
    return (
      <Message
        title={
          <>
            <h4>Sorry, we encountered an error with your submission:</h4>
            <p>"{error}"</p>
          </>
        }
        description={
          <h5>Please contact info@pathways2.college and provide the error message above.</h5>
        }
      />
    );
  } else if (modalOpen && (completed || milestoneClosed)) {
    return (
     <>
        <ClosedModal
          show={modalOpen}
          onHide={() => setModalOpen(false)}
          completed={completed}
          closed={milestoneClosed}
        />
        {renderCollector(definition, milestone, data, currentPage, setLoading, setError, setSuccess, setModalOpen)}
      </>
    );
  } else if (success) {
    return (
      <Message
        title={
          <>
            <h4>You’ve submitted Milestone 10!</h4>
            <h5>Great work! You should receive your $20 CFC shortly!</h5>
          </>
        }
        description={
          <span className='text-left'>
            <p style={{textAlign: 'center'}}>P2C Bot will text and email you about your next Milestone on the first Monday of the month: August 1st.</p>
          </span>
        }
      />
    );
  } else {
    return(renderCollector(definition, milestone, data, currentPage, setLoading, setError, setSuccess, setModalOpen));
  }
}

function ClosedModal({completed, closed, ...props}) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="modal-title">
          Information
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {completed ? (
          <p>You completed this milestone on <b>{completed}</b>. You should receive CFC payment shortly after completion.</p>
        ) : (
          <p>This Milestone is still available to complete, but can no longer be submitted for CFC and Savings Fund Payment.</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
          <Button onClick={props.onHide}>Close</Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default Milestone10;