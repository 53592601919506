//strings represent keys the json file expects to be provided from the React codebase
export const VAR_KEYS = {
    preferredName: 'preferred_name',
    milestoneNumber: 'milestone',
    milestoneTitle: 'milestoneTitle',
    omegaDeadline: 'omegaDeadline'
}

export const SURVEY_PAGES = {
    INITIAL: 'introduction_page_1',
    WRAPUP: 'wrapUp_page_9',
    PRINTABLE: [
        'introduction_page_1',
        'faq_page_2',
        'tabs_page_3',
        'application_page_4',
        'nextSteps_page_5',
        'applicationReply_page_6',
        'wrapUp_page_9'
    ]
}

export const MILESTONE_DEADLINE = new Date('12/02/2022');

export const MILESTONE_OMEGA_DEADLINE_STRING = `${MILESTONE_DEADLINE.toLocaleDateString('en-us', { /*weekday:"long", year:"numeric",*/ month:"long", day:"numeric"})}nd`

export const MILESTONE_OMEGA_NUMBER = '13';

export const MILESTONE_TITLE = 'P2C Program Closeout';

export const MILESTONE_OMEGA_ROUTE = `/milestone${MILESTONE_OMEGA_NUMBER}`;