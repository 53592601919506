export default function MaintenanceMode() {
  return (
    <div className="login-flow">
      <div className="login-flow__form-container">
        <div className="login-logo-container">
          <img src="/images/p2c-logo.svg" alt="Pathways2College Logo" className="login-logo" />
          <span className="logo-dark">
            Pathways
          </span>
          <span className="logo-light">
            2
          </span>
          <span className="logo-dark">
            College
          </span>
        </div>
        <h1 className="maintenance-title">The Pathways2College program has ended</h1>
          <div className="mb-3">
              <p className="maintenance-paragraph">The Pathways2College program has ended. Thank you for your participation!</p>
          </div>
          <div className="mb-3">
              <p className="maintenance-paragraph">If you are a former Pathways2College student looking for help with Tremendous rewards, please email help@tremendous.com.</p>
          </div>
      </div>
    </div>
  );
}