import { useState, useEffect, useCallback, useRef } from 'react';
import cn from 'classnames';
import styles from 'styles/school-bank.module.scss';
import { PlusCircleFill } from 'react-bootstrap-icons';
import { OverlayTrigger, Tooltip, Popover } from 'react-bootstrap';
import ReactSlider from 'react-slider'
import { debounce } from 'lib/performance';
import { getMilestone, genericPost } from 'lib/API';
import { BounceLoader } from 'react-spinners';
import { thousandsSeparator } from 'lib/format';

// CSS colors
import vars from 'styles/vars.module.scss';

export default function RecommendationsTable({ valuesHash, preferenceMetrics, addRow, userGpa = 0, milestone = 8, showFoS = false }) {
  const [sliderValue, setSliderValue] = useState(userGpa);
  const [schools, setSchools] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pristine, setPristine] = useState(true);
  const metricsForTable = preferenceMetrics.filter(metric => metric.name in valuesHash && valuesHash[metric.name] === 'yes');
  const takenSchools = (valuesHash['four_year_school_bank'] ? valuesHash['four_year_school_bank'].map(school => school['INSTNM']) : [])
    .concat(valuesHash['two_year_school_bank'] ? valuesHash['two_year_school_bank'].map(school => school['INSTNM']) : [])
    .concat(valuesHash['training_school_bank'] ? valuesHash['training_school_bank'].map(school => school['INSTNM']) : []);
  const filteredSchools = (schools && schools.length) ? schools.filter(school => !takenSchools.includes(school['INSTNM'])) : [];

  const gpaRef = useRef(sliderValue);
  gpaRef.current = sliderValue;

  useEffect(() => {
    getRecommendations(gpaRef);
  }, [sliderValue]);

  const longValueFields = [
    'STUDY_OPTIONS', 'DEMOGRAPHICS', 'ATHLETICS'
  ];

  const getRecommendations = useCallback(
    debounce(gpaVal => {
      setLoading(true);
      getMilestone(milestone, { gpa: gpaVal.current }).then(({ match_data }) => {
        setLoading(false);
        const rows = [...match_data];
        rows.sort((a, b) => {
          return b['roi'] - a['roi'];
        });
        setSchools(rows);
        setPristine(false);
      })
      .catch(err => {
        console.error(err);
        setLoading(false);
      });
    }, 700),
    []
  );

  const addRowWithNoFloats = (school) => {
    const keys = Object.keys(school);
    const newRow = {};
    keys.forEach(key => {
      const val = school[key];
      if (typeof val === 'number' && val % 1 !== 0) {
        newRow[key] = String(val);
      } else {
        newRow[key] = val;
      }
    });
    addRow({...newRow, priority: '', from_recommendations: true});
    genericPost('milestone', {
      milestone: '8',
      action: 'add',
      value: {...newRow, priority: '', from_recommendations: true}
    });
  }

  return (
    <>
    <div className={styles.sliderContainer}>
      <ReactSlider
        className={cn("horizontal-slider", styles.slider)}
        thumbClassName={styles.sliderThumb}
        trackClassName={styles.sliderTrack}
        min={Number(userGpa)}
        max={(Number(userGpa) + 1) > 4.5 ? 4.5 : (Number(userGpa) + 1)}
        step={0.1}
        value={sliderValue}
        onChange={(value) => setSliderValue(value)}
        renderThumb={(props, state) => <div {...props} />}
      />
      <span className={styles.gpaValue}>{Number(sliderValue).toFixed(1)} GPA</span>
      <div className={styles.sliderMsg}>
        {sliderValue > userGpa ? <span>What if I improve my GPA by <b>{(sliderValue-userGpa) > 0.9 ? '1' : (sliderValue - userGpa).toFixed(1)}</b> point{(sliderValue-userGpa) > 0.9 ? '':'s'}?</span> : null}
      </div>
    </div>
    {loading ? <div className={styles.loading}><BounceLoader color='#b5160b' /></div> : null}
    {!loading && !schools.length && !pristine ? <div className={styles.noResults}>Sorry there are no schools that meet all of the criteria you selected. Please try reducing your criteria by returning to the previous page: <b>What Matters to Me</b> and changing some responses to “No.”</div> : null}
    {!loading && schools.length ? (
      <div className={styles.recommendationsTable}>
        <div className={styles.leftSide}>
          <table className={styles.table}>
            <thead>
              <th></th>
              <th>School</th>
              <th className={styles.schoolBankDesktopCell}>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Popover id="popover-eec" style={{width: 450, maxWidth: 450}}>
                      <Popover.Title as="h3"><b>Estimated Earnings Comparison (EEC):</b></Popover.Title>
                      <Popover.Content style={{color: vars.text}}>
                        <p>A dollar figure comparing your estimated yearly earnings for attending a specific school to your earnings if you start working right after High School. EEC accounts for the estimated money you’ll spend on your loan payment. A school with a higher EEC may enable you to earn more money over the long term (even if it costs more money in the short term).</p>
                        <p><i>Example: An EEC of <b>+$20,000</b> means that after subtracting your annual loan payment, you would make an estimated $20,000 <b>more per year</b> 10 years after high school than if you attended no school.</i></p>
                      </Popover.Content>
                    </Popover>
                  }
                >
                  <span>EEC</span>
                </OverlayTrigger>
              </th>
              <th className={styles.schoolBankDesktopCell}>Net Price</th>
            </thead>
            {filteredSchools.map((row, index) => (
              <tr key={index}>
                <td>
                  <span className={cn('fake-link', styles.schoolBankDesktop)} onClick={()=>addRowWithNoFloats(row)}>Add</span>
                  <span className={cn('fake-link', styles.schoolBankMobile)} onClick={()=>addRowWithNoFloats(row)}><PlusCircleFill /></span>
                </td>
                <td>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip placement="top">{row['INSTNM']}</Tooltip>
                    }
                  >
                    <a href={row['INSTURL']} target='_blank'>{row['INSTNM']}</a>
                  </OverlayTrigger>
                </td>
                <td className={styles.schoolBankDesktopCell} style={{fontWeight: 700, color: getEecColor(row.roi)}}>{getEECSign(row)}{row.roi || row.roi === 0 ? `$${getEEC(row)}`:''}</td>
                <td className={styles.schoolBankDesktopCell}>{row['NET_PRICE'] ? '$':''}{thousandsSeparator(row['NET_PRICE'])}</td>
              </tr>
            ))}
          </table>
        </div>
        <div className={styles.rightSide}>
          <table className={cn(styles.table, styles.tableRight)}>
            <thead>
              <tr>
                <th className={styles.schoolBankMobileCell}>EEC</th>
                <th className={styles.schoolBankMobileCell}>Net Price</th>
                {showFoS ? <th>Field of Study</th> : null}
                <th>Acceptance Rate</th>
                <th>A-G Course Requirement</th>
                {metricsForTable.map(metric => <th key={metric.text}>{metric.text}</th>)}
              </tr>
            </thead>
            {filteredSchools.map((row, index) => (
              <tr key={index}>
                <td className={styles.schoolBankMobileCell} style={{fontWeight: 700, color: getEecColor(row.roi)}}>{getEECSign(row)}{row.roi || row.roi === 0 ? `$${getEEC(row)}`:''}</td>
                <td className={styles.schoolBankMobileCell}>{row['NET_PRICE'] ? '$':''}{thousandsSeparator(row['NET_PRICE'])}</td>
                {showFoS ? <td>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip placement="top">{row['MAJORS']}</Tooltip>
                    }
                  >
                    <span>{row['MAJORS']}</span>
                  </OverlayTrigger>
                </td> : null}
                <td>{showValue({prop: 'ADM_RATE', format: 'percent'}, row)}</td>
                <td>{row['AG_REQUIREMENTS']}</td>
                {metricsForTable.map(metric => <td key={metric['INSTNM']}>{longValueFields.includes(metric.prop) ? (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip placement="top">{showValue(metric, row)}</Tooltip>
                    }
                  >
                    <span>{showValue(metric, row)}</span>
                  </OverlayTrigger>
                ) : showValue(metric, row)}</td>)}
              </tr>
            ))}
          </table>
        </div>
      </div>
    ): null}
    </>
  )
}
export function getEECSign(row) {
  const numRoi = Number(row.roi);
  if (!numRoi && numRoi !== 0) {
    return '';
  }
  return numRoi >= 0 ? '+' : '-';
}
export function getEEC(row) {
  const numRoi = Number(row.roi);
  if (!numRoi && numRoi !== 0) {
    return '';
  }
  const roundedRoi = Math.round(numRoi);
  return thousandsSeparator(Math.abs(roundedRoi));
}
export function showValue(metric, row) {
  if (!row[metric.prop]) {
    return '';
  }
  if ('format' in metric) {
    if (metric.format === 'percent') {
      return metric['prop'] in row ? `${!!Number(row[metric['prop']]) ? Math.round(Number(row[metric['prop']])*100) : '0'}%` : '';
    }
    if (metric.format === 'miles') {
      return metric['prop'] in row && !!parseInt(row[metric['prop']]) ? `${parseInt(row[metric['prop']])} miles` : '';
    }
  }
  return metric['prop'] in row ? row[metric['prop']] : '';
}
export function getEecColor(eec) {
  try {
    const nEec = Number(eec);
    if (nEec <= -500) return '#A23E48';
    if (nEec <= -1) return '#FF3C38';
    if (nEec === 0) return '#004869';
    if (nEec >= 70000) return '#19381F';
    if (nEec >= 60000) return '#145528';
    if (nEec >= 50000) return '#237837';
    if (nEec >= 40000) return '#2E9646';
    if (nEec >= 30000) return '#34AB4E';
    if (nEec >= 20000) return '#45D060';
    if (nEec >= 10000) return '#43E66F';
    if (nEec >= 1) return '#28F384';
  } catch (err) {
    return '#004869';
  }
  
}