import React from "react";
import Downshift from 'downshift'
import * as Survey from "survey-react";

export class SelectModel extends Survey.Question {
  getType() {
    return "select";
  }
  validateValueCallback() {
    const surveyValues = this.surveyValue.valuesHash;
    if (!this.isRequired && !this.questionValue) {
      return false;
    }
    if (this.questionValue && this.not_duplicated_from) {
      if (this.questionValue === surveyValues[this.not_duplicated_from]) {
        return new Survey.CustomError(this.validationErrorText ? this.validationErrorText : 'Please select two different options.', this.survey);
      }
    }
  }
}

const menuStyles = {
  maxHeight: 380,
  width: '100%',
  overflowY: 'scroll',
  listStyle: 'none',
  position: 'relative',
  boxShadow: ' rgb(0 0 0 / 12%) 0px 3px 14px 2px',
  borderRadius: '0.3rem',
  padding: '10px 0',
  backgroundColor: 'white'
}
export class Select extends Survey.SurveyElementBase {
  get question() {
    return this.props.question;
  }
  render() {
    if (!this.question) return null;
    var cssClasses = this.question.cssClasses;

    const initialValue = this.question.choices.reduce((prev, curr) => {
      if (curr.value === this.question.value) {
        return curr;
      }
      return prev;
    }, null);

    return (
      <div className={cssClasses.root}>
        <Downshift
          onChange={(selection) => {
            this.question.value = selection.value;
          }}
          itemToString={(item) => (item ? item.value : '')}
          initialSelectedItem={initialValue}
        >
          {({
            getItemProps,
            getMenuProps,
            getToggleButtonProps,
            isOpen,
            selectedItem,
            getRootProps,
          }) => (
      <div>
        <div
          className="select-container"
          {...getRootProps({}, {suppressRefError: true})}
        >
          <button type="button" {...getToggleButtonProps()} className="sv_q_dropdown_control button-select" aria-label={'toggle menu'}>
            {selectedItem && selectedItem.text || 'Choose...'}
          </button>
        </div>
        {isOpen
            ? <ul {...getMenuProps()} style={menuStyles}>
          {this.question.choices.map((item, index) => (
                  <li
                    {...getItemProps({
                      key: item.value,
                      index,
                      item,
                      className:"select-option",
                      style: {
                        fontWeight: selectedItem === item ? 'bold' : 'normal'
                      },
                    })}
                  >
                    {item.text}
                  </li>
                ))}
        </ul>: null}
      </div>
    )}
  </Downshift>
      </div>
    );
  }
}

Survey.Serializer.addClass(
  "select",
  [
    { name: "choices" },
    { name: "not_duplicated_from" },
  ],
  function() {
    return new SelectModel("");
  },
  "question"
);

Survey.ReactQuestionFactory.Instance.registerQuestion("select", props => {
  return React.createElement(Select, props);
});