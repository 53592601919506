import { useState, useRef, useCallback, useMemo } from 'react';
import cn from 'classnames';
import styles from 'styles/school-bank.module.scss';
import Downshift from 'downshift';
import { XCircleFill, CaretDownFill, CaretUpFill, PencilSquare } from 'react-bootstrap-icons';
import { Modal, OverlayTrigger, Tooltip, Button, Popover } from 'react-bootstrap';
import { range } from 'ramda';
import { debounce } from 'lib/performance';
import { getEecColor, showValue, getEEC, getEECSign } from './RecommendationsTable';
import { thousandsSeparator, firstLetterUpper } from 'lib/format';
import twoIcon from 'images/2-square-fill.svg';
import fourIcon from 'images/4-square-fill.svg';
import tIcon from 'images/fonts.svg';
// API
import { getMilestone, genericPost } from 'lib/API';

// CSS colors
import vars from 'styles/vars.module.scss';

export default function SchoolBankTable({ rows: unsortedRows, valuesHash, preferenceMetrics, addRow, removeRow, updateRow, schoolType,
  allowCustomText, showSortMessage, willApply = false, showFoS = false, willApplyTable = false, milestone = '8' }) {
  const [sortBy, setSortBy] = useState('roi');
  const [sortDirection, setSortDirection] = useState('desc');
  const [dummyState, setDummyState] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [notes, setNotes] = useState('');
  const [schoolForModal, setSchoolForModal] = useState('');
  const metricsForTable = preferenceMetrics.filter(metric => metric.name in valuesHash && valuesHash[metric.name] === 'yes');

  const rowsRef = useRef(unsortedRows);
  rowsRef.current = unsortedRows;

  const longValueFields = [
    'STUDY_OPTIONS', 'DEMOGRAPHICS', 'ATHLETICS'
  ];

  let preSortedRows = [...unsortedRows];
  let rows = useMemo(() => sortRows(preSortedRows, sortBy, sortDirection), [sortBy, sortDirection, preSortedRows]);

  const getTypeIcon = (t) => {
    if (t === 'four_year_school_bank') return fourIcon;
    if (t === 'training_school_bank') return tIcon;
    return twoIcon;
  }
  const typesDict = {
    training_school_bank: 'Trade School / Training Program',
    two_year_school_bank: 'Two-year School',
    four_year_school_bank: 'Four-year School',
  }

  const onSortableThClick = (prop) => {
    if (sortBy === prop) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(prop);
      if (prop === 'roi') {
        setSortDirection('desc');
      } else {
        setSortDirection('asc');
      }
    }
  }
  const toggleApply = (name) => {
    const index = rows.findIndex(row => row['INSTNM'] === name);
    if (index > -1) {
      const newRow = rows[index];
      newRow.will_apply = !newRow.will_apply;
      updateRow(newRow);
      setDummyState(dummyState + 1);
    }
  }
  const handlePriorityChange = (name, value) => {
    const index = rows.findIndex(row => row['INSTNM'] === name);
    if (index > -1) {
      const newRow = rows[index];
      newRow.priority = value;
      updateRow(newRow);
      setDummyState(dummyState + 1);
    }
  }
  const handleOpenNotes = (school) => {
    setSchoolForModal(school);
    setNotes(school['notes']);
    setModalOpen(true);
  }

  const handleNotesChange = (school, notes) => {
    setNotes(notes);
    setNotesDebounced(school, notes);
  }
  const setNotesDebounced = useCallback(
    debounce((name, notesVal) => {
      const index = rowsRef.current.findIndex(row => row['INSTNM'] === name);
      if (index > -1) {
        const newRow = rowsRef.current[index];
        newRow.notes = notesVal;
        updateRow(newRow);
        setDummyState(dummyState + 1);
      }
    }, 700),
    []
  );
  const handleRemoveRow = (school) => {
    removeRow(school['INSTNM']);
    genericPost('milestone', {
      milestone,
      action: 'remove',
      value: school
    });
  }
  const getCaret = (direction) => {
    if (direction === 'asc') {
      return <CaretUpFill />;
    } else {
      return <CaretDownFill />;
    }
  }
  return (
    <>
    {showSortMessage ? (
      <p className={styles.sortNote}>Note: Click on the column headers to resort the table</p>
    ):null}
    <div className={styles.schoolBankTable}>
      <div className={styles.leftSide}>
        <table className={styles.table}>
          <thead>
            <tr>
            {willApply ? (
              <th />
            ):null}
            {willApplyTable ? null : <th/>}
            <th
              className={styles.sortableTh}
              onClick={() => onSortableThClick('INSTNM')}
              colSpan={willApplyTable ? 2:1}
            >School{sortBy === 'INSTNM' ? getCaret(sortDirection) : null}</th>
            <th
              className={cn(styles.sortableTh, styles.schoolBankDesktopCell)}
              onClick={() => onSortableThClick('priority')}
            >Priority{sortBy === 'priority' ? getCaret(sortDirection) : null}</th>
            <th
              className={cn(styles.sortableTh, styles.schoolBankDesktopCell)}
              onClick={() => onSortableThClick('roi')}
            >
              <OverlayTrigger
                placement="top"
                overlay={
                  <Popover id="popover-eec" style={{width: 450, maxWidth: 450}}>
                    <Popover.Title as="h3"><b>Estimated Earnings Comparison (EEC):</b></Popover.Title>
                    <Popover.Content style={{color: vars.text}}>
                      <p>A dollar figure comparing your estimated yearly earnings for attending a specific school to your earnings if you start working right after High School. EEC accounts for the estimated money you’ll spend on your loan payment. A school with a higher EEC may enable you to earn more money over the long term (even if it costs more money in the short term).</p>
                      <p><i>Example: An EEC of <b>+$20,000</b> means that after subtracting your annual loan payment, you would make an estimated $20,000 <b>more per year</b> 10 years after high school than if you attended no school.</i></p>
                    </Popover.Content>
                  </Popover>
                }
              >
                <span>EEC</span>
              </OverlayTrigger>{sortBy === 'roi' ? getCaret(sortDirection) : null}
            </th>
            <th
              className={cn(styles.sortableTh, styles.schoolBankDesktopCell)}
              onClick={() => onSortableThClick('NET_PRICE')}
            >Net Price{sortBy === 'NET_PRICE' ? getCaret(sortDirection) : null}</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row, index) => (
              <tr key={index}>{willApply ? (
                <td>
                  <button onClick={() => toggleApply(row['INSTNM'])} className={styles[row.will_apply ? 'undoButton':'willApplyButton']}>{row.will_apply ? 'Undo' : 'Will Apply' }</button>
                </td>
              ):null}
                <td style={{display: 'flex', alignItems: 'center', justifyContent: 'center', ...(willApplyTable ? {borderRight: 'none', paddingRight: 0}:null)}}>
                  {willApplyTable ? (
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip placement="top">{typesDict[row.school_type]}</Tooltip>}
                    >
                      <img src={getTypeIcon(row.school_type)} style={{marginRight: 5}} />
                    </OverlayTrigger>
                  ) : <>
                    <span className={cn('fake-link', styles.schoolBankDesktop)} onClick={()=>handleRemoveRow(row)}>Remove</span>
                    <span className={cn('fake-link', styles.schoolBankMobile)} onClick={()=>handleRemoveRow(row)}><XCircleFill /></span>
                  </>}
                </td>
                <td className={styles.tdName}>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip placement="top">{row['INSTNM']}</Tooltip>
                    }
                  >
                    <a href={row['INSTURL']} target='_blank'>{row['INSTNM']}</a>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip placement="top">Add notes</Tooltip>}
                  >
                    <button onClick={()=>handleOpenNotes(row)} className={styles.notesButton}><PencilSquare /></button>
                  </OverlayTrigger>
                </td>
                <td className={cn(styles.tdPriority, styles.schoolBankDesktopCell)}>{willApplyTable ? firstLetterUpper(row.priority) : <Priority onSelect={(value) => handlePriorityChange(row['INSTNM'], value)} selected={row.priority} />}</td>
                <td className={styles.schoolBankDesktopCell} style={{fontWeight: 700, color: getEecColor(row.roi)}}>{getEECSign(row)}{Number(row.roi) || Number(row.roi) === 0 ? `$${getEEC(row)}`:''}</td>
                <td className={styles.schoolBankDesktopCell}>{row['NET_PRICE'] && row['NET_PRICE'] !== 'Not Available' ? '$':''}{thousandsSeparator(row['NET_PRICE'])}</td>
              </tr>
            ))}
            {willApplyTable ? null : (
              <tr>
                {willApply ? (
                    <td/>
                ):null}
                <td />
                <td className={styles.tdInput}>
                  <Autocomplete
                    allowCustomText={allowCustomText} schoolType={schoolType} addRow={addRow} selected={rows.map(r=>r['INSTNM'])} milestone={milestone} />
                </td>
                <td className={styles.schoolBankDesktopCell}/>
                <td className={styles.schoolBankDesktopCell}/>
                <td className={styles.schoolBankDesktopCell}/>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className={styles.rightSide}>
        <table className={cn(styles.table, styles.tableRight)}>
          <thead>
            <tr>
              <th
                className={cn(styles.sortableTh, styles.schoolBankMobileCell)} onClick={() => onSortableThClick('priority')}
              >
                Priority{sortBy === 'priority' ? getCaret(sortDirection) : null}
              </th>
              <th
                className={cn(styles.sortableTh, styles.schoolBankMobileCell)} onClick={() => onSortableThClick('roi')}
              >
                EEC{sortBy === 'roi' ? getCaret(sortDirection) : null}
              </th>
              <th
                className={cn(styles.sortableTh, styles.schoolBankMobileCell)} onClick={() => onSortableThClick('NET_PRICE')}
              >
                Net Price{sortBy === 'NET_PRICE' ? getCaret(sortDirection) : null}
              </th>
              {willApplyTable ? (
                <th className={styles.sortableTh}
                onClick={() => onSortableThClick('DEADLINE')}>Application Date{sortBy === 'DEADLINE' ? getCaret(sortDirection) : null}</th>
              ):null}
              {showFoS ? <th>Field of Study</th> : null}
              <th className={styles.sortableTh} onClick={() => onSortableThClick('ADM_RATE')}>
                Acceptance Rate{sortBy === 'ADM_RATE' ? getCaret(sortDirection) : null}
              </th>
              <th className={styles.sortableTh} onClick={() => onSortableThClick('AG_REQUIREMENTS')}>
                A-G Course Requirement (Courses Completed){sortBy === 'AG_REQUIREMENTS' ? getCaret(sortDirection) : null}
              </th>
              {metricsForTable.map(metric => (
                <th
                  className={styles.sortableTh}
                  key={metric.text}
                  onClick={() => onSortableThClick(metric.prop)}
                >{metric.text}{metric.prop === sortBy ? getCaret(sortDirection) : null}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, index) => (
              <tr key={index}>
                <td className={cn(styles.tdPriority, styles.schoolBankMobileCell)}><Priority onSelect={(value) => handlePriorityChange(row['INSTNM'], value)} selected={row.priority} /></td>
                <td className={styles.schoolBankMobileCell} style={{fontWeight: 700, color: getEecColor(row.roi)}}>{getEECSign(row)}{Number(row.roi) || Number(row.roi) === 0 ? `$${getEEC(row)}`:''}</td>
                <td className={styles.schoolBankMobileCell}>{row['NET_PRICE'] && row['NET_PRICE'] !== 'Not Available' ? '$':''}{thousandsSeparator(row['NET_PRICE'])}</td>
                {willApplyTable ? (
                  <td>
                    {getDeadlineTooltipText(row['DEADLINE'], row['INSTNM']).length > 20 ? (
                      <OverlayTrigger
                          placement="left"
                          overlay={
                            <Tooltip placement="left">{getDeadlineTooltipText(row['DEADLINE'], row['INSTNM'])}</Tooltip>
                          }
                        >
                          <span>{row['DEADLINE'] ? row['DEADLINE'] : 'See school’s website'}</span>
                        </OverlayTrigger>
                    ): (
                      <span>{row['DEADLINE'] ? row['DEADLINE'] : 'See school’s website'}</span>
                    )}
                  </td>
                ):null}
                {showFoS ? <td>
                  <OverlayTrigger
                    placement="left"
                    overlay={
                      <Tooltip placement="left">{row['MAJORS']}</Tooltip>
                    }
                  >
                    <span>{row['MAJORS']}</span>
                  </OverlayTrigger>
                </td> : null}
                <td>{showValue({prop: 'ADM_RATE', format: 'percent'}, row)}</td>
                <td>{row['AG_REQUIREMENTS']} <b>({Math.round(valuesHash['user_ag_number'])})</b></td>
                {metricsForTable.map(metric => <td key={metric['INSTNM']}>{longValueFields.includes(metric.prop) ? (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip placement="top">{showValue(metric, row)}</Tooltip>
                    }
                  >
                    <span>{showValue(metric, row)}</span>
                  </OverlayTrigger>
                ) : showValue(metric, row)}</td>)}
              </tr>
            ))}
            {willApplyTable ? null : (
              <tr>
                {range(0, 3).map(v => <td className={styles.schoolBankMobileCell} key={v} />)}
                {range(0, metricsForTable.length + (showFoS ? 3 : 2)).map(v => <td key={v} />)}
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <NotesModal
        show={modalOpen}
        onHide={() => setModalOpen(false)}
        notes={notes}
        school={schoolForModal}
        setNotes={handleNotesChange}
        metricsForTable={metricsForTable}
      />
    </div>
    </>
  )
}
function getDeadlineTooltipText(deadline, school) {
  if (school === 'Stanford University') {
    return 'Majors that do not require an arts portfolio have an application deadline of 11/1/22.';
  }
  if (school === 'University of Southern California') {
    return 'Early Action deadline is 11/1/22. Majors that do not require a portfolio or audition have an application deadline of 1/15/23.';
  }
  if (deadline === 'Rolling Admissions') {
    return 'Applications are accepted continually through the beginning of the school year.';
  }
  return '';
}
function sortRows(unsortedRows, sortBy, sortDirection) {
  const rows = [...unsortedRows];
  if (sortBy) {
    const numericColumns = ['ADM_RATE', 'AG_REQUIREMENTS', 'NET_PRICE', 'EEC', 'roi', 'RETENTION_RATE', 'COMPLETION_RATE', 'distance_from_hs'];
    return rows.sort((a, b) => {
      if (numericColumns.includes(sortBy)) {
        // A big number if there is no value so the row will be at the bottom
        const lastValue = sortDirection === 'asc' ? Number.MAX_SAFE_INTEGER : Number.MIN_SAFE_INTEGER;
        const aVal = (a[sortBy] || a[sortBy] === 0) ? Number(a[sortBy]) : lastValue;
        const bVal = (b[sortBy] || b[sortBy] === 0) ? Number(b[sortBy]) : lastValue;
        return sortDirection === 'asc' ? aVal - bVal : bVal - aVal;
      } else if (sortBy === 'DEADLINE') {
        const aVal = a[sortBy] && a[sortBy].includes('/') ? new Date(a[sortBy]) : a[sortBy].toLowerCase();
        const bVal = b[sortBy] && b[sortBy].includes('/') ? new Date(b[sortBy]) : b[sortBy].toLowerCase();
        if (typeof aVal === 'object' && typeof bVal === 'object') {
          return sortDirection === 'asc' ? aVal - bVal : bVal - aVal;
        } else if (typeof aVal === 'object') {
          return -1;
        } else if (typeof bVal === 'object') {
          return 1;
        }
        return sortDirection === 'asc' ? aVal.localeCompare(bVal) : bVal.localeCompare(aVal);
      } else {
        const lastValue = sortDirection === 'asc' ? 'zzz' : 'aaa';
        const aVal = a[sortBy] ? a[sortBy].toLowerCase() : lastValue;
        const bVal = b[sortBy] ? b[sortBy].toLowerCase() : lastValue;
        if (['yes', 'no'].includes(aVal) && ['yes', 'no'].includes(bVal)) {
          return sortDirection === 'asc' ? bVal.localeCompare(aVal) : aVal.localeCompare(bVal);
        }
        return sortDirection === 'asc' ? aVal.localeCompare(bVal) : bVal.localeCompare(aVal);
      }
    });
  }
  return unsortedRows;
}

function NotesModal({notes, school, metricsForTable, setNotes, ...props}) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="modal-title">
          {school['INSTNM']}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={styles.schoolDetailsModal}>
          {school.roi ? (
            <div>
              <span className={styles.questionTitle}>EEC</span>
              <p style={{fontWeight: 700, color: getEecColor(school.roi)}}>
                {Number(school.roi) && Number(school.roi) !== 0 ? (Number(school.roi) >= 0 ? '+':'-') : ''}{school.roi ? `$${thousandsSeparator(Math.abs(school.roi))}`:''}
              </p>
            </div>):null}
          {school.NET_PRICE ? (
            <div>
              <span className={styles.questionTitle}>Net Price</span>
              <p>
                {school['NET_PRICE'] && school['NET_PRICE'] !== 'Not Available' ? '$':''}{thousandsSeparator(school['NET_PRICE'])}
              </p>
            </div>):null}
          {school.ADM_RATE ? (
            <div>
              <span className={styles.questionTitle}>Acceptance Rate</span>
              <p>
                {showValue({prop: 'ADM_RATE', format: 'percent'}, school)}
              </p>
            </div>):null}
          {school.AG_REQUIREMENTS || school.AG_REQUIREMENTS === 0 ? (
            <div>
              <span className={styles.questionTitle}>A-G Course Requirement</span>
              <p>
                {school['AG_REQUIREMENTS']}
              </p>
            </div>):null}
          {metricsForTable.map(metric => school[metric.prop] ? (
            <div key={metric['INSTNM']}>
              <span className={styles.questionTitle}>{metric.text}</span>
              <p>
                {showValue(metric, school)}
              </p>
            </div>
          ):null)}
        </div>
        <label className={styles.questionTitle}>Notes:</label>
        <textarea
          className={cn(styles.notesTextarea, 'form-control')}
          value={notes}
          onChange={e => setNotes(school['INSTNM'], e.target.value)}
        />
      </Modal.Body>
      <Modal.Footer>
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
          <Button onClick={props.onHide}>Close</Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

function Priority({ onSelect, selected }) {
  return (
    <select className='form-control' value={selected} onChange={(e) => onSelect(e.target.value)}>
      <option value=''>Choose</option>
      <option value='Target'>Target</option>
      <option value='Dream'>Dream</option>
      <option value='Safety'>Safety</option>
    </select>
  )
}

function Autocomplete({ selected, addRow, schoolType, allowCustomText, milestone }) {
  const [loading, setLoading] = useState(false);
  const [schools, setSchools] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const queryRef = useRef(inputValue);
  queryRef.current = inputValue;

  const getSchools = useCallback(
    debounce(query => {
      setLoading(true);
      getMilestone(milestone, { query: query.current, school_type: schoolType }).then(({ search_data }) => {
        if (!search_data) {
          setSchools([]);
          setLoading(false);
          return;
        }
        setLoading(false);
        setSchools(search_data);
      })
      .catch(err => {
        console.error(err);
        setLoading(false);
      });
    }, 700),
    []
  );

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    getSchools(queryRef);
  }
  return (
    <Downshift
      onChange={(selection) => {
        if (selection && 'INSTNM' in selection && !selected.includes(selection['INSTNM'])) {
          const keys = Object.keys(selection);
          const newRow = {};
          keys.forEach(key => {
            const val = selection[key];
            if (typeof val === 'number' && val % 1 !== 0) {
              newRow[key] = String(val);
            } else {
              newRow[key] = selection[key];
            }
          });
          addRow({...newRow, priority: ''});
        }
        setSchools([]);
        setInputValue('');
      }}
      itemToString={(item) => (item ? item.value : '')}
    >
      {({
        getItemProps,
        getMenuProps,
        getInputProps,
        selectedItem,
        getRootProps,
      }) => (
        <>
          <div
            className={styles.autocompleteInputWrapper}
            {...getRootProps({}, {suppressRefError: true})}
          >
            <input
              {...getInputProps()}
              className={styles.autocompleteInput}
              placeholder="Type to search..."
              onChange={handleInputChange}
              value={inputValue}
              onFocus={() => setIsOpen(true)}
              onBlur={() => setIsOpen(false)}
            />
          </div>
          {(isOpen && 'length' in schools && schools.length > 0) || loading || (isOpen && allowCustomText && inputValue.length) ? <ul {...getMenuProps()} className={styles.menuStyles}>
            {(!loading && inputValue.length && allowCustomText)
              ? (
                <li
                  {...getItemProps({
                    key: 'custom-value',
                    index: 0,
                    item: {"INSTNM": inputValue, custom: true}
                  })}
                  className={cn("select-option", styles.customSelectOption)}>
                  <span>{inputValue}</span><span className={styles.customBadge}>Custom</span>
                </li>
              )
              : null}
            {loading ? (
              <li className="select-option">
                Loading...
              </li>
            ) : schools.filter(ch=>!selected.includes(ch['INSTNM']))
              .map((item, index) => (
                <li
                  {...getItemProps({
                    key: item.value,
                    index: allowCustomText ? index + 1 : index,
                    item,
                    className:"select-option",
                    style: {
                      fontWeight: selectedItem === item ? 'bold' : 'normal'
                    },
                  })}
                >
                  {item['INSTNM']}
                </li>
              ))}
          </ul>: null}
        </>
      )}
    </Downshift>
  )
}