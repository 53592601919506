import React from "react";
import * as Survey from "survey-react";
import cn from "classnames";
import styles from 'styles/accordion.module.scss';
import Downshift from 'downshift';
import { CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';

export class AccordionModel extends Survey.Question {
  getType() {
    return "accordion";
  }

  getPrintableContent(vars) {

    const surveyData = Object.keys(this.surveyValue?.valuesHash).length > 0 ? this.surveyValue.valuesHash : vars;

    const validElements = this.elements.filter((elem) => {
      if (!elem.hasOwnProperty('html')) return false;
      if (!elem.condition) return true;
      return validateCondition(elem.condition, surveyData);
    });
    const surveyHeader = this.drawerTitle.length ? this.drawerTitle.replace('CLICK HERE FOR ', '') : null;

    if (validElements.length > 0) {
      return <div className='accordion-panel' key={this.id}>
        {surveyHeader && <header className='accordion-title'>{surveyHeader}</header>}
        {validElements.map((elem, idx) => <div key={idx} className='accordion-item' dangerouslySetInnerHTML={{ __html: elem.html }} />)}
      </div>
    } else {
      return <></>;
    }
  }
}

export function validateCondition(condition, valuesHash) {
  const [variable, operator, value] = condition.split(" ");
  if (operator === 'contains') {
    return valuesHash[variable]?.includes(value);
  }
  if (operator === 'notcontains') {
    return valuesHash[variable] && !valuesHash[variable].includes(value);
  }
  if (operator === '=') {
    return valuesHash[variable] === value;
  }
  return false;
}

export class Accordion extends Survey.SurveyElementBase {
  constructor(props) {
    super(props);
    this.contentRef = React.createRef();
    this.state = {
      isOpen: false,
    };
  }
  get question() {
    return this.props.question;
  }
  render() {
    const valuesHash = this.question.surveyValue?.valuesHash;
    const validElements = this.question.elements.filter((elem) => {
      if (!elem.condition) return true;
      return validateCondition(elem.condition, valuesHash);
    });
    if (!this.question) return null;
    var cssClasses = this.question.cssClasses;
    let drawerTitle = this.question.drawerTitle;
    if (this.question.conditionalTitle) {
      const { title, condition } = this.question.conditionalTitle;
      if(validateCondition(condition, valuesHash)) {
        drawerTitle = title;
      }
    }
    return (
      <div className={cssClasses.root}>
        <button
          onClick={() => {
            this.setState({isOpen: !this.state.isOpen});
          }}
          className={cn({
            [styles.accordion]: true,
            [styles.active]: this.state.isOpen
          })}
        >{drawerTitle}{this.state.isOpen ? <CaretUpFill width="16px" /> : <CaretDownFill width="16px" />}</button>
        <div
          className={cn({
            [styles.panel]: true,
            [styles.panelOpen]: this.state.isOpen
          })}
        >
          {validElements.map((elem, idx) => {
            const richHtml = elem.html.replace(/\{[\w]+\}/g, (match, offset, str) => {
              const variable = match.replace(/[\{\}]/g, "");
              return typeof valuesHash[variable] === 'string' ? valuesHash[variable] : (valuesHash[variable].length ? valuesHash[variable].join(", ") : "");
            });;

            return (
              <div key={idx} className={styles.item} dangerouslySetInnerHTML={{__html: richHtml}} />
            );
          })}
          <div className={styles.closeButtonContainer}>
            <button
              className={styles.closeButton}
              onClick={() => {
                this.setState({isOpen: !this.state.isOpen});
              }}
            >Close</button>
          </div>
        </div>
      </div>
    );
  }
}

Survey.Serializer.addClass(
  "accordion",
  [
    { name: "elements" },
    { name: "conditionalTitle" },
    { name: "drawerTitle" }
  ],
  function() {
    return new AccordionModel("");
  },
  "question"
);

Survey.ReactQuestionFactory.Instance.registerQuestion("accordion", props => {
  return React.createElement(Accordion, props);
});