import React, { createRef } from "react";
import { Question, SurveyElementBase, Serializer, ReactQuestionFactory } from "survey-react";
import { ArrowUpCircle } from 'react-bootstrap-icons';
import { withRouter } from "react-router-dom";
import { replaceSurveyDataVariablesInHtmlString } from 'lib/print/printUtils';
import $ from 'jquery';
import styles from 'styles/faq.module.scss';

const CUSTOM_TYPE = "faq";
const CUSTOM_PROPERTIES = {
  QUESTION_LIST: "questions"
}

const FaqContents = ({ questionList, surveyVars={} }) => {
  return <div>{questionList.map((q, idx) => <section className={styles.faq__question} key={`section-${idx}`}>
    <header id={`q-${idx}`} className={'section-subheading'}>{q.title}
      <a href="#faq-menu" aria-label={"Jump to Top"} className={'no-print'}>
        <ArrowUpCircle />
      </a>
    </header>
    <div className={styles.htmlWrapper} dangerouslySetInnerHTML={{ __html: replaceSurveyDataVariablesInHtmlString(surveyVars, q.answer) }} />
  </section>)
  }</div>
}

//CREATE A MODEL
export class FAQModel extends Question {
  getType() {
    return CUSTOM_TYPE;
  }

  getPrintableContent() {
    return <div className={styles.faq} key={this.id}>
      <FaqContents questionList={this[CUSTOM_PROPERTIES.QUESTION_LIST]} surveyVars={this.surveyValue.valuesHash} />
    </div>
  }

}

class FAQBase extends SurveyElementBase {
  constructor(props) {
    super(props);
    this.jumpLinksRef = createRef();
    this.navRef = createRef();
  }

  get question() {
    return this.props.question;
  }

  get questionList() {
    return this.props.question[CUSTOM_PROPERTIES.QUESTION_LIST];
  }

  get pathname() {
    return `#${this.props.history.location.pathname}`;
  }

  get surveyVars() {
    return this.props.creator.survey.valuesHash;
  }

  componentDidMount() {
    this.jumpLinksRef.current = [];
  }

  componentDidUpdate() {
    if (this.questionList.length) {
      this.jumpLinksRef.current = this.jumpLinksRef.current.slice(0, this.questionList.length);
    }
  }

  componentWillUnmount() {
    //remove any lingering jump links (without rerendering) since this is a SPA
    window.history.replaceState(null, "", this.pathname);
  }

  handleJumpToMenu = (e) => {
    e.preventDefault();

    if (this.navRef.current) {
      this.navRef.current.scrollIntoView({
        behavior: 'smooth',
      });

      $('.section-subheading').removeClass('focused');

    }
  };

  handleJumpToSection = (e) => {
    e.preventDefault();
    const idx = e.target.href.split("-")[1];
    const questionId = e.target.href.split("#")[1];

    if (this.jumpLinksRef.current && this.jumpLinksRef.current[idx]) {
      this.jumpLinksRef.current[idx].scrollIntoView({
        behavior: 'smooth',
      });
      this.jumpLinksRef.current[idx].focus();
      if ($(`#${questionId}`)) {
        $('.section-subheading').removeClass('focused');
        $(`#${questionId}`).addClass('focused');
      }
    }
  };

  render() {
    if (!this.question) return null;

    return (
      <div className={styles.faq}>
        <nav className={styles.faq__nav}>
          <h3 id="faq-menu" className='section-subheading' ref={this.navRef}>Jump to Question:</h3>
          <ul>
            {this.question[CUSTOM_PROPERTIES.QUESTION_LIST].map((q, idx) => <li key={`navLink-${idx}`}>
              <a href={`#q-${idx}`} onClick={this.handleJumpToSection}>{q.title}</a>
            </li>)}
          </ul>
        </nav>
        <div className='faq-question-body'>{this.questionList.map((q, idx) => <section className={styles.faq__question} key={`section-${idx}`}>
          <header
            id={`q-${idx}`}
            className={'section-subheading'}
            ref={ref => {
              if (this.jumpLinksRef.current) {
                this.jumpLinksRef.current[idx] = ref
              } return true
            }}>
            {q.title}
            <a href="#faq-menu" aria-label={"Jump to Top"} className={'no-print'} onClick={this.handleJumpToMenu}>
              <ArrowUpCircle />
            </a>
          </header>
          <div className={styles.htmlWrapper} dangerouslySetInnerHTML={{ __html: replaceSurveyDataVariablesInHtmlString(this.surveyVars, q.answer) }} />
        </section>)
        }</div>
      </div>
    );
  }
}

export const FAQ = withRouter(FAQBase);

//CONFIGURE JSON SERIALIZATION
/*
addClass accepts: 
  name (from Model's getType method), 
  properties (array of objects used in JSON serialization, including all custom model properties)
  creator (a function that returns an instance of the model's class)
  parentName (name of parent class that this class extends)
*/
Serializer.addClass(
  CUSTOM_TYPE,
  [
    { name: CUSTOM_PROPERTIES.QUESTION_LIST }
  ],
  () => new FAQModel(""),
  "question"
);


//REGISTER THE MODEL
ReactQuestionFactory.Instance.registerQuestion(CUSTOM_TYPE, props => React.createElement(FAQ, props));
