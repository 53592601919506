import * as Survey from "survey-react";
import "survey-react/survey.css";
import showdown from 'showdown';
import { Container } from 'react-bootstrap';

// API
import { setMilestoneValue } from 'lib/API';

import definition from './definition-surveyjs.json';
import surveyClasses from "../../styles/surveyClasses";

const localSurveyClasses = {
  ...surveyClasses,
  ranking: {
    itemIndex: "sv-ranking-item__index milestone1-ranking"
  }
}

// Custom Components
export { FormattedNumber } from "../../components/survey-widgets/FormattedNumber";
export { Date } from "../../components/survey-widgets/Date";

const Milestone1 = () => {
  let model = new Survey.Model(definition);
  var converter = new showdown.Converter();
  model.onTextMarkdown.add(function (survey, options) {
    //convert the markdown text to html
    var str = converter.makeHtml(options.text);
    //remove root paragraphs <p></p>
    str = str.substring(3);
    str = str.substring(0, str.length - 4);
    //set html
    options.html = str;
  });  

  const onSurveyComplete = (survey, options) => {
    //Write survey results into database
    console.log("Survey results: " + JSON.stringify(survey.data));
  }
  const onSurveyValueChange = (survey, options) => {
    setMilestoneValue(
      options.question.name,
      options.value === false ? 'false' : options.value,
      '1');
  }
  function onSurveyPageChange(survey, options) {
    if (options.newCurrentPage && options.newCurrentPage.name) {
      setMilestoneValue("current_page", options.newCurrentPage.name, '1')
    }
  }
  return (
    <Container fluid="md" className="survey-container">
      <Survey.Survey
        model={model}
        onComplete={onSurveyComplete}
        onValueChanging={onSurveyValueChange}
        css={localSurveyClasses}
        currentPageNo={0}
        onCurrentPageChanged={onSurveyPageChange}
        focusFirstQuestionAutomatic={false}
      />
    </Container>    
  )
}

export default Milestone1;