import { renderToString } from 'react-dom/server';
import { cssClassStyles, cssElementStyles } from "./printStyles";
import { QUESTION_TYPES_THAT_RENDER_PRINTABLE_CONTENT } from "./printVars";
import { createPrintableIframe, hidePrintableSurveyOnPage } from './iframeUtils';

export const formatInlineCss = (cssObjectItem) => Object.entries(cssObjectItem).reduce((styleString, [property, val]) => {
  styleString += `${property}:${val};`
  return styleString;
}, '');

export const addInlineCssStylesFromCssObject = (htmlString = '') => {
  const escapeRegExp = (stringToGoIntoTheRegex) => stringToGoIntoTheRegex.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
  const classNameRegex = (cn) => new RegExp(`(?:class)[=]["|'](?:${escapeRegExp(cn)})["|']`, 'g');
  const htmlElementRegex = (el) => new RegExp(`[<](?:${escapeRegExp(el)})`, 'g');

  let res = Object.keys(cssClassStyles).reduce((htmlStringWithInlineStyles, cssKey) => {
    const s = htmlStringWithInlineStyles.replace(
      classNameRegex(cssKey),
      `style="${formatInlineCss(cssClassStyles[cssKey])}"`
    )
    return s;
  }, htmlString)

  res = Object.keys(cssElementStyles).reduce((htmlStringWithInlineStyles, cssKey) => {
    const s = htmlStringWithInlineStyles.replace(
      htmlElementRegex(cssKey),
      `<${cssKey} style="${formatInlineCss(cssElementStyles[cssKey])}"`
    )
    return s;
  }, res)

  return res;
}

export const replaceSurveyDataVariablesInHtmlString = (surveyVars, htmlString = '') => {
  for (const [k, v] of Object.entries(surveyVars)) {
    htmlString = htmlString.replace(new RegExp(`{${k}}`, 'g'), v)
  }
  return htmlString;
}

const getPrintableHtmlStringFromSurvey = (surveyModel, printablePagesList, surveyVars) => {
  return <div className='sv_body' style={{ display: 'block', width: '100%' }}>{(surveyModel.pages.reduce((items, p) => {
    const pageItems = [];
    if (printablePagesList.includes(p.name)) {
      p.elements.forEach(e => {
        const t = e.getType();
        if (t === 'html' && e.propertyHash.isVisible !== false) {
          pageItems.push(<div className={'question-content'} dangerouslySetInnerHTML={{ __html: e.html }} key={e.id} />);
        } else if (QUESTION_TYPES_THAT_RENDER_PRINTABLE_CONTENT.includes(t)) {
          pageItems.push(e.getPrintableContent(surveyVars))
        } else {
          // console.log(`not printing ${e.propertyHash.name}`, e)
        }
      })
    }
    return pageItems.length > 0
      ? [...items, <>{pageItems.map((i, idx) => {
        return <div className='question-row' key={`page-item-${idx}`}>
          <div className='question-root'>{i}</div>
        </div>
      })}
      </>]
      : items;
  }, []))
  }</div>;

}

export const createPrintableSurvey = (surveyModel, printablePagesList, targetDom) => {
  const content = <><div className='sv_header' style={{ padding: 0, borderBottom: 'none' }}>{surveyModel.title}</div>{
    getPrintableHtmlStringFromSurvey(surveyModel, printablePagesList, surveyModel.valuesHash)
  }</>
  const printableContent = replaceSurveyDataVariablesInHtmlString(surveyModel.valuesHash, renderToString(content));
  preparePrintableContent(printableContent, targetDom)
};

export const preparePrintableContent = (printableContent, targetDom) => {
  if (printableContent) {
    hidePrintableSurveyOnPage(printableContent, targetDom);
    // console.log("hid it")
    createPrintableIframe();
    // console.log("made it")
  }
}