const surveyClasses = {
  matrix: {
      root: "table table-striped"
  },
  navigationButton: "button btn-lg",
  question: {
    title: 'question-title',
    mainRoot: 'question-root',
    content: 'question-content'
  },
  row: 'question-row',
  navigation: {
    next: 'navigation-btn btn-next',
    prev: 'navigation-btn btn-prev',
    complete: 'navigation-btn btn-complete'
  },
  boolean: {
    switch: 'custom-boolean-switch',
  },
  checkbox: {
    label: 'checkbox-label'
  },
  signaturepad: {
    clearButton: 'signature-clear-button'
  }
};

export default surveyClasses;