import { useEffect, useState } from "react";
import * as Survey from "survey-react";
import "survey-react/survey.css";
import showdown from 'showdown';
import { Container, Modal, Button } from 'react-bootstrap';

// API
import { getMilestone, setMilestoneValue, apiRequest } from 'lib/API';

import Loading from 'components/Loading';
import Message from 'components/Message';

import definition from './definition.json';
import surveyClasses from "../../styles/surveyClasses";

import { dateInPast } from 'lib/time';
import { matterMostDict } from 'lib/m8_dicts';

// Custom Components
export { Select } from "../../components/survey-widgets/Select";
export { Carousel } from "../../components/survey-widgets/Carousel";
export { Date } from "../../components/survey-widgets/Date";
export { Cards } from "../../components/survey-widgets/Cards";
export { FormattedNumber } from "../../components/survey-widgets/FormattedNumber";
export { AutocompleteList } from "../../components/survey-widgets/AutocompleteList";
export { SchoolBank } from "../../components/survey-widgets/SchoolBank";
export { Recommendations } from "../../components/survey-widgets/Recommendations";
export { Accordion } from "../../components/survey-widgets/Accordion";

const renderCollector = (definition, milestone, data, currentPage, setLoading, setError, setSuccess, setModalOpen, setLocalResponses) => {
  let model = new Survey.Model(definition);

  var converter = new showdown.Converter();
  model.onTextMarkdown.add(function (survey, options) {
    //convert the markdown text to html
    var str = converter.makeHtml(options.text);
    //remove root paragraphs <p></p>
    str = str.substring(3);
    str = str.substring(0, str.length - 4);
    //set html
    options.html = str;
  });

  const onSurveyComplete = (survey, options) => {
    setLoading(true);
    const data = {
      publicColleges: 'california_public_schools_considered' in survey.valuesHash ? survey.valuesHash['california_public_schools_considered'] : [],
      privateColleges: 'private_schools_considered' in survey.valuesHash ? survey.valuesHash['private_schools_considered'] : [],
      standoutAdvantages: [survey.valuesHash['standout_advantages_a'], survey.valuesHash['standout_advantages_b']],
      standoutDisadvantages: [survey.valuesHash['standout_disadvantages_a'], survey.valuesHash['standout_disadvantages_b']]
    }
    setLocalResponses(data);
    apiRequest('post', '/milestone', {
      data: {
        milestone: milestone,
        key: 'submission'
      }
    })
    .then(response => {
      setModalOpen(true);
      setSuccess(true);
      setLoading(false);
    })
    .catch(error => {
      let message = 'Unknown Error';
      try {
        message = error.response.data.error_message;
      } catch {
      }
      setError(message);
      setLoading(false);
    });
  }

  const onSurveyValueChange = (survey, options) => {
    setMilestoneValue(options.question.name, options.value, milestone);
  }
  function onSurveyPageChange(survey, options) {
    if (options.newCurrentPage && options.newCurrentPage.name) {
      setMilestoneValue("current_page", options.newCurrentPage.name, milestone)
    }    
  }

  if (data) {
    model.data = data;
  }

  return (
    <Container fluid="md" className="survey-container survey-title-blue">
      <Survey.Survey
        model={model}
        onComplete={onSurveyComplete}
        onValueChanging={onSurveyValueChange}
        css={surveyClasses}
        currentPage={currentPage}
        onCurrentPageChanged={onSurveyPageChange}
      />
    </Container>
  );
}

const Milestone8 = ({ preferredName }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError]     = useState("");
  const [success, setSuccess] = useState(false);
  const [completed, setCompleted] = useState('');
  const [data, setData] = useState(false);
  const [currentPage, setCurrentPage] = useState(false);
  const [modalOpen, setModalOpen] = useState(true);
  const [localRespones, setLocalResponses] = useState({});

  const milestone = '8';

  useEffect(() => {
    getMilestone(milestone).then(({m5_data, m6_data, m7_data, user_aggpa, user_ag_number, match_data, enrollment_school, user_gpa, current_server_time, ...responses}) => {
      let jsonResponse = {};
      let keys = Object.keys(responses);
      for (let key of keys) {
        jsonResponse[key] = responses[key].value;
        if (key === 'submission' && responses[key].timestamp) {
          const dateStr = String(responses[key].timestamp);
          const completedDate = dateStr.slice(4,6)+'-'+dateStr.slice(6,8)+'-'+dateStr.slice(0,4);
          jsonResponse['completed'] = completedDate;
          setCompleted(completedDate);
        }
      }
      const {
        submission,
        current_page,
        ...surveyData } = jsonResponse;
      if (current_page) {
        setCurrentPage(current_page);
      }
      const isClosed = dateInPast(new Date(2022, 4, 30), current_server_time) ? 'yes' : 'no';
      if (submission === true || submission === 'true' || isClosed === 'yes') {
        setCurrentPage('presentation_page');
      }

      const { completed_m5, good_at: goodAt } = m5_data;
      const { completed_m6, p1q10r1, p1q10r2 } = m6_data;
      const {
        completed_m7,
        options_considered: optionsC,
        private_schools_considered: private_sc,
        california_public_schools_considered: public_sc
      } = m7_data;
      const most = p1q10r1 && 'value' in p1q10r1 ? p1q10r1.value : '';
      const secondMost = p1q10r2 && 'value' in p1q10r2 ? p1q10r2.value : '';
      const options_considered = optionsC && 'value' in optionsC ? optionsC.value : [];
      const schools_of_interest_private = private_sc && 'value' in private_sc ? private_sc.value : [];
      const schools_of_interest_public = public_sc && 'value' in public_sc ? public_sc.value : [];
      const schools_of_interest = schools_of_interest_private.concat(schools_of_interest_public);
      const good_at = goodAt && 'value' in goodAt ? goodAt.value : {};
      const goodAtKeys = ['first', 'second', 'third'];
      const goodAtStr = goodAtKeys.filter(key => key in good_at).reduce((acc, key, idx, arr) => {
        if (arr.length === 1 || idx === 0) return good_at[key];
        if (idx === arr.length -1) return acc + ' and ' + good_at[key];
        return acc + ', ' + good_at[key];
      }, '');

      surveyData['completed_m5'] = completed_m5 ? 'yes' : 'no';
      surveyData['completed_m6'] = completed_m6 ? 'yes' : 'no';
      surveyData['completed_m7'] = completed_m7 ? 'yes' : 'no';
      surveyData['user_aggpa'] = user_aggpa;
      surveyData['user_ag_number'] = user_ag_number;
      surveyData['march_most_important'] = `${matterMostDict[most]} and ${matterMostDict[secondMost]}`;
      surveyData['school_dependent_year'] = enrollment_school === 'Fremont' ? 'third quarter junior year' : 'first semester junior year';
      surveyData['user_gpa'] = parseFloat(user_gpa);
      surveyData['fos'] = surveyData['fos'] ? surveyData['fos'].map(v => typeof v === 'string' ? v : v['value']) : [];
      surveyData['m5_good_at'] = goodAtStr;
      surveyData['m7_selected_pathways'] = options_considered.join(', ');;
      surveyData['m7_schools_of_interest'] = schools_of_interest.join(', ');
      surveyData['m7_show_schools_of_interest'] = completed_m7 && surveyData['m7_schools_of_interest'].length ? 'yes' : 'no';
      surveyData['milestoneClosed'] = isClosed;

      const ra = surveyData['religious_affiliation_preference'];
      surveyData['religious_affiliation_preference'] = typeof ra === 'string' ? [ra] : ra;

      setData(surveyData);
      setLoading(false);
    }).catch(error => {
      console.log(error);
      setError("Unable to load your responses.");
      setLoading(false);
    });
  }, []);

  // NOTE: PLEASE UPDATE THE DATE INSIDE THE PROMISE ALSO
  const milestoneClosed = dateInPast(new Date(2022, 4, 30), data ? data.current_server_time : false);

  if (loading) {
    return (
      <Loading/>
    );
  } else if (error !== "") {
    return (
      <Message
        title={
          <>
            <h4>Sorry, we encountered an error with your submission:</h4>
            <p>"{error}"</p>
          </>
        }
        description={
          <h5>Please contact info@pathways2.college and provide the error message above.</h5>
        }
      />
    );
  } else if (modalOpen && (completed || milestoneClosed)) {
    return (
     <>
        <ClosedModal
          show={modalOpen}
          onHide={() => setModalOpen(false)}
          completed={completed}
          closed={milestoneClosed}
        />
        {renderCollector(definition, milestone, data, currentPage, setLoading, setError, setSuccess, setModalOpen, setLocalResponses)}
      </>
    );
   } else if (success) {
    return (
      <Message
        title={
          <>
            <h4>You’ve submitted Milestone 8!</h4>
            <h5>Great work! You should receive your $25 CFC within seven days of submitting.</h5>
          </>
        }
        description={
          <span className='text-left'>
            <p style={{textAlign: 'center'}}>P2C Bot will text and email you about your next Milestone on the first Monday of the month: June 6th.</p>
          </span>
        }
      />
    );
  } else {
    return(renderCollector(definition, milestone, data, currentPage, setLoading, setError, setSuccess, setModalOpen, setLocalResponses));
  }
}

function ClosedModal({completed, closed, ...props}) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="modal-title">
          Information
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {completed ? (
          <p>You completed this milestone on <b>{completed}</b>. You should receive CFC payment within 7 days of completion.</p>
        ) : (
          <p>This Milestone is now closed and can no longer be submitted for CFC and Savings Fund Payment.</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
          <Button onClick={props.onHide}>Close</Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default Milestone8;