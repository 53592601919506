import React from "react";
import * as Survey from "survey-react";
import cn from "classnames";
import styles from 'styles/school-bank.module.scss';
import RecommendationsTable from "components/RecommendationsTable";

export class RecommendationsModel extends Survey.Question {
  getType() {
    return "recommendations";
  }
}

export class Recommendations extends Survey.SurveyElementBase {
  // Constructor
  constructor(props) {
    super(props);
    this.state = { renderTrigger: 0};
  }
  get question() {
    return this.props.question;
  }
  componentDidMount() {
    const currentSurvey = this.props.creator.survey;
    currentSurvey.onValueChanged = {
      fire: (survey, options) => {
        this.setState({ renderTrigger: this.state.renderTrigger + 1 });
      }
    }
  }
  render() {
    if (!this.question) return null;
    var cssClasses = this.question.cssClasses;

    const addRow = (row) => {
      console.log(row.SCHOOL_TYPE)
      if (row['SCHOOL_TYPE'].indexOf('Trade') > -1) {
        this.props.creator.survey.getQuestionByName('training_school_bank').value = this.props.creator.survey.getQuestionByName('training_school_bank').value 
        ? [...this.props.creator.survey.getQuestionByName('training_school_bank').value, {...row, priority: ''}]
        : [{...row, priority: ''}];
        //this.setState({ renderTrigger: this.state.renderTrigger + 1 });
        return;
      }
      if(row['SCHOOL_TYPE'] === '4-Year School') {
        this.props.creator.survey.getQuestionByName('four_year_school_bank').value = this.props.creator.survey.getQuestionByName('four_year_school_bank').value 
        ? [...this.props.creator.survey.getQuestionByName('four_year_school_bank').value, {...row, priority: ''}]
        : [{...row, priority: ''}];
        //this.setState({ renderTrigger: this.state.renderTrigger + 1 });
        return;
      }
      if(row['SCHOOL_TYPE'] === '2-Year School') {
        this.props.creator.survey.getQuestionByName('two_year_school_bank').value = this.props.creator.survey.getQuestionByName('two_year_school_bank').value
        ? [...this.props.creator.survey.getQuestionByName('two_year_school_bank').value, {...row, priority: ''}]
        : [{...row, priority: ''}];
        //this.setState({ renderTrigger: this.state.renderTrigger + 1 });
        return;
      }
    };
    const valuesHash = this.question.surveyValue?.valuesHash;
    return (
      <div className={cssClasses.root}>
        <RecommendationsTable
          addRow={addRow}
          valuesHash={valuesHash}
          preferenceMetrics={this.question.preference_metrics}
          renderTrigger={this.state.renderTrigger}
          userGpa={this.props.creator.survey.getQuestionByName('user_gpa').value}
          milestone={this.question.milestone}
          showFoS={this.question.show_fos}
        />
      </div>
    );
  }
}

Survey.Serializer.addClass(
  "recommendations",
  [
    { name: "preference_metrics" },
    { name: "show_fos" },
    { name: "milestone" }
  ],
  function() {
    return new RecommendationsModel("");
  },
  "question"
);

Survey.ReactQuestionFactory.Instance.registerQuestion("recommendations", props => {
  return React.createElement(Recommendations, props);
});