import React from "react";
import * as Survey from "survey-react";
import cn from 'classnames';
import { Tabs as TabsBootstrap, Tab } from 'react-bootstrap';
import { validateCondition } from "./Accordion";

// Styles
import styles from 'styles/dashboard.module.scss';

export class TabsModel extends Survey.Question {
  getType() {
    return "tabs";
  }

  validateValueCallback() {
    const { questionValue: { activeTab, questionsValues: values }, tabs } = this;
    const tabToValidate = tabs.reduce((acc, tab) => {
      if (tab.key === activeTab) return tab;
      return acc;
    }, {});
    const activeTabValues = values[activeTab];
    let valid = true;
    tabToValidate.items.forEach(question => {
      if (question.isRequired && !activeTabValues[question.name]) {
        if (!question.visibleIf) {
          valid = false;
        } else {
          // Check if the question is visible
          if(validateCondition(question.visibleIf, activeTabValues)) {
            valid = false;
          }
        }
      }
    });
    return valid ? false : new Survey.CustomError("Please answer all the required questions.");
  }

  getPrintableContent() {
    const { tabs } = this;
    const tabContents = tabs.map(tab => tab.items.reduce((tabPanel, tabItem) => {
      if (tabItem.type === 'html') {
        return tabPanel += tabItem.content.replace("section-title", "section-subheading");
      } else {
        return tabPanel;
      }
    }, `<header class='section-title'>${tab.title}</header>`));
    return <div className={styles.surveyTabContent} key={this.id}>{tabContents.map(t => <div key={t.key} dangerouslySetInnerHTML={{ __html: t }} />)}</div>;
  }
}

export class Tabs extends Survey.SurveyElementBase {
  get question() {
    return this.props.question;
  }
  constructor(props) {
    super(props);
    if (!this.question.value) {
      const initialQuestionsValues = props.question.tabs.reduce((tAcc, tCurr) => {
        let tabs = {};
        const values = tCurr.items.reduce((acc, item) => {
          if (!item.name) return acc;
          acc[item.name] = null;
          return acc;
        }
          , {});
        tabs[tCurr.key] = values;
        return { ...tAcc, ...tabs };
      }, {});
      const initialState = {
        activeTab: 'fafsa',
        questionsValues: initialQuestionsValues
      };
      this.state = initialState;
      this.question.value = initialState;
    } else {
      this.state = this.question.value;
    }
  }

  render() {
    if (!this.question) return null;
    var cssClasses = this.question.cssClasses;

    const updateQuestionValue = (name, value) => {
      let questionsValues = this.state.questionsValues;
      let activeTab = this.state.activeTab;
      Object.keys(questionsValues).forEach(tabKey => {
        if (tabKey === activeTab) {
          questionsValues[tabKey][name] = value;
        } else {
          Object.keys(questionsValues[tabKey]).forEach(key => {
            questionsValues[tabKey][key] = null;
          });
        }
      })
      this.question.value = { ...this.state, questionsValues };
      this.setState({ questionsValues });
    }

    return (
      <div className={cssClasses.root}>
        <TabsBootstrap
          activeKey={this.state.activeTab}
          onSelect={(k) => {
            this.question.value = { ...this.state, activeTab: k };
            this.setState({ activeTab: k });
          }}
          id="take-home-tabs"
          className="nav-tabs__left">
          {this.question.tabs.map((tab) => {
            const validElements = tab.items.filter((elem) => {
              if (!elem.visibleIf) return true;
              return validateCondition(elem.visibleIf, this.state.questionsValues[tab.key]);
            });
            return (
                <Tab style={{position: 'relative'}} className={styles.surveyTabContent} key={tab.key} eventKey={tab.key} title={tab.title}>
                {validElements.map((item, idx) => {
                  if (item.type === 'html') {
                      return <div key={idx} dangerouslySetInnerHTML={{__html: item.content}}/>
                  } else if (item.type === 'radiogroup') {
                    return <div role="radiogroup">
                        <h3 className="question-title" style={{marginBottom: 33}}>
                        <span>{item.title}</span>
                        {item.isRequired && <span className="sv_q_required_text">&nbsp;*</span>}
                      </h3>
                      <div key={idx} className="question-content">
                        <fieldset className="sv_qcbc">
                          {item.choices.map((choice) => {
                            return (
                                  <div className={cn({sv_q_radiogroup: true, "sv-q-col-1": true, checked: this.state.questionsValues[this.state.activeTab][item.name] === choice.value})} onClick={() => updateQuestionValue(item.name, choice.value)}>
                                <label key={choice.value} className="sv_q_radiogroup_label" aria-label={choice.title}>
                                  <input type="radio" name={item.name} value={choice.value} className="sv_q_radiogroup_control_item" aria-hidden="true" />
                                  <span title={choice.text}>
                                    <span className="sv-string-viewer">{choice.text}</span>
                                  </span>
                                </label>
                              </div>
                            )
                          })}
                        </fieldset>
                      </div>
                    </div>
                  } else if (item.type === 'text') {
                    return <>
                        <h3 className="question-title" style={{marginBottom: 25, paddingTop: 10}}>
                        <span>{item.title}</span>
                        {item.isRequired && <span className="sv_q_required_text">&nbsp;*</span>}
                      </h3>
                      <div key={idx} className="question-content">
                        <input type="text" name={item.name} className="sv_q_text_root" placeholder={item.placeholder} value={this.state.questionsValues[this.state.activeTab][item.name]} onChange={(e) => updateQuestionValue(item.name, e.target.value)} />
                      </div>
                    </>
                  } else {
                    return <div key={idx} />
                  }
                })}

              </Tab>
              )})}
        </TabsBootstrap>
      </div>
    );
  }
}

Survey.Serializer.addClass(
  "tabs",
  [
    { name: "tabs" }
  ],
  function() {
    return new TabsModel("");
  },
  "question"
);

Survey.ReactQuestionFactory.Instance.registerQuestion("tabs", props => {
  return React.createElement(Tabs, props);
});