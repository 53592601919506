import React from "react";
import * as Survey from "survey-react";
import cn from 'classnames';
import { CheckCircleFill } from 'react-bootstrap-icons';

export class CardsModel extends Survey.Question {
  getType() {
    return "cards";
  }

  validateValueCallback() {
    if (!this.mustRead) {
      return false;
    }
    if (this.mustRead && (!this.questionValue || this.questionValue.length < this.cards.length)) {
      return new Survey.CustomError(this.validationErrorText ? this.validationErrorText : 'Please read all the cards.', this.survey);
    }
    return false;
  }
}

export class Cards extends Survey.SurveyElementBase {
  get question() {
    return this.props.question;
  }
  constructor(props) {
    super(props);
  }
  render() {
    if (!this.question) return null;
    var cssClasses = this.question.cssClasses;

    return (
      <div className={cssClasses.root}>
        <div className={cn({
          'cards-container': true,
          'cards-container--two-columns': this.question.twoColumns
        })}>
          {this.question.cards.map((item, index) => (
            <div className={cn({
              "flip-card": true,
              "flip-card--two-columns": this.question.twoColumns,
              "flip-card--active": this.question.value && 'length' in this.question.value && this.question.value.includes(index)
            })} onClick={() => {
              const seenSet = this.question.value && 'length' in this.question.value ? new Set(this.question.value) : new Set();
              seenSet.add(index);
              const seenArray = Array.from(seenSet);
              this.question.value = seenArray;
            }}>
              <div className="flip-card-inner">
                {typeof item.image !== 'undefined' ? (
                  <div className="flip-card-front flip-card-front__image">
                    <h1 className="flip-card__title">{item.title}</h1>
                    <img className="flip-card__image" src={`/images/milestones/milestone10/${item.image}`} alt={item.title} />
                  </div>
                ) : (
                  <div className="flip-card-front">
                    <h1 className="flip-card__title">{item.title}</h1>
                  </div>
                )}
                <div className={`flip-card-back${typeof item.align !== 'undefined' ? ` flip-card-back__align-${item.align}`: ''}`}>
                <h1 className="flip-card__title">{item.title}</h1>
                  <p dangerouslySetInnerHTML={{__html: item.content}} />
                </div>
              </div>
              {this.question.value && 'length' in this.question.value && this.question.value.includes(index) ? (
                <CheckCircleFill className="flip-card__check-icon"/>
              ):null}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

Survey.Serializer.addClass(
  "cards",
  [
    { name: "cards" },
    { name: "mustRead" },
    { name: "twoColumns" }
  ],
  function() {
    return new CardsModel("");
  },
  "question"
);

Survey.ReactQuestionFactory.Instance.registerQuestion("cards", props => {
  return React.createElement(Cards, props);
});