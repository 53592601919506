export const pop_size_dict = {
  less_than_2k: "Less than 2,000 students",
   ['2k_15k']: "2,000-15,000 students",
   more_than_15k: "More than 15,000 students"
};
export const school_types_dict = {
  ['4_year_college']: "4-year college",
  ['2_year_college']: "2-year college",
  training_program: "Training program"
};
export const study_options_dict = {
  distance_online_courses: "Distance learning / online courses",
  ESL: "English as a Second Language (ESL) courses",
  weekend_courses: "Weekend courses"
};
export const special_mission_dict = {
  women_only: "Women-Only College",
   men_only: "Men-Only College",
   historically_black: "Historically Black College and University"
}
export const sports_dict = {
  baseball: "Baseball",
   basketball: "Basketball",
   football: "Football",
   track_cross_country: "Track and Cross Country ",
   any: "Any NCAA/NCCAA/NSCAA/NJCAA/NAIA/NAA sports program"
}
export const matterMostDict = {
  cost: "Cost",
  campus_life: "Campus life",
  financial_aid: "Financial aid or loans available",
  scholarships: "Scholarships available",
  work: "Ability to work while in school",
  online: "Ability to attend online",
  close_to_home: "Being close to home",
  somewhere_new: "Being somewhere new",
  live_at_home: "Ability to live at home while attending school",
  major_program: "Major or Academic Program",
  athletic_program: "Athletic Programs",
  academically_challenging: "How academically challenging it seems",
  career_networking: "Career networking",
  potential_earnings: "Potential earnings",
  alumni_in_family: "Alumni in family",
  knowing_others_attenting: "Knowing other students who are also attending",
  prestige: "Prestige",
  other: "Other"
};
export const preference_metrics = [
  {
    "name": "care_about_accepts_adt_tag",
    "text": "ADT/TAG",
    "prop": "ACCEPTS_ADT_LACC_YN"
  },
  {
    "name": "care_about_study_options",
    "text": "Study Options",
    "prop": "STUDY_OPTIONS"
  },
  {
    "name": "care_about_retention_rate",
    "text": "Retention Rate",
    "prop": "RETENTION_RATE",
    "format": "percent"
  },
  {
    "name": "care_about_graduation_rate",
    "text": "Graduation Rate",
    "prop": "COMPLETION_RATE",
    "format": "percent"
  },
  {
    "name": "care_about_it_is_public",
    "text": "Public",
    "prop": "PUBLIC_YN"
  },
  {
    "name": "care_about_distance_from_home",
    "text": "Distance From Home",
    "prop": "distance_from_hs",
    "format": "miles"
  },
  {
    "name": "care_about_population_size",
    "text": "Population Size",
    "prop": "SIZE"
  },
  {
    "name": "care_about_campus_locale",
    "text": "Campus Locale",
    "prop": "LOCALE"
  },
  {
    "name": "care_about_underrepresented_communities",
    "text": "Underrepresented Communities",
    "prop": "DEMOGRAPHICS"
  },
  {
    "name": "care_about_special_mission",
    "text": "Special Mission",
    "prop": "SPECIAL_MISSION"
  },
  {
    "name": "care_about_religious_affiliation",
    "text": "Religious Affiliation",
    "prop": "RELIGIOUS_AFFILIATION"
  },
  {
    "name": "care_about_athletic_programs",
    "text": "Athletic Programs",
    "prop": "ATHLETICS"
  },
  {
    "text": "Fields of Study",
    "prop": "MAJORS"
  },
  {
    "text": "School Type",
    "prop": "SCHOOL_TYPE"
  },
  {
    "text": "Application Date",
    "prop": "DEADLINE"
  },
  {
    "text": "Acceptance Rate",
    "prop": "ADM_RATE"
  },
  {
    "text": "Priority",
    "prop": "priority"
  },
  {
    "text": "EEC",
    "prop": "roi"
  },
  {
    "text": "State",
    "prop": "STABBR"
  },
  {
    "text": "School Website",
    "prop": "INSTURL"
  },
  {
    "text": "A-G Requirements",
    "prop": "AG_REQUIREMENTS"
  },
  {
    "text": "Net Price",
    "prop": "NET_PRICE"
  }
];