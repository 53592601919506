import React from "react";
import { withRouter } from 'react-router-dom';
import { Question, SurveyElementBase, Serializer, ReactQuestionFactory } from "survey-react";
import LinkInNewTab from "components/LinkInNewTab";
import { TABS as DASHBOARD_TABS } from 'components/Dashboard';
import { MILESTONE_OMEGA_DEADLINE_STRING } from 'components/milestones/milestoneOmega/vars';

const CUSTOM_TYPE = "html-saving-page";
const CUSTOM_PROPERTIES = {
  M12_COMPLETED: "m12_completed"
}

const SECTIONS = {
  // INTRO: 'saving_p1', - left as "html" type
  M6_TAKEHOME: 'saving_p2',
  WILL_APPLY: 'saving_p3',
  M12: 'saving_p4',
  M12_INCOMPLETE: 'saving_p4_alt',
  // MILESTONE_OMEGA: 'saving_p5'  - left as "html" type
}

const PastMilestonesLink = () => <LinkInNewTab
  pathName={`/#/dashboard/${DASHBOARD_TABS.PAST_MILESTONES.eventKey}`}
  linkText={"Past Milestones"} />;
  const WillApplyLink = () => <LinkInNewTab
  pathName={`/#/dashboard/${DASHBOARD_TABS.WILL_APPLY.eventKey}`}
  linkText={'Will Apply'} />
const M6TakehomeLink = () => <LinkInNewTab
    pathName={`/#/take-home`}
    linkText={'Milestone 6 Takehome: My Timelines'} />
const M12Link = () => <LinkInNewTab
  pathName={`/#/milestone12`}
  linkText={'Milestone 12'} />
const SchoolBankLink = () => <LinkInNewTab
  pathName={`/#/milestone12/finalize-list-of-schools`}
  linkText={'your M12 School Bank'} />

const SavingYourWorkContents = ({ section, m12Completed = true }) => {
  if (section === SECTIONS.INTRO) {
    return <>
      <header>
        <span className='section-title'>Preserve Your Information</span>
      </header>
      <p>
        <span className='bold'>After {MILESTONE_OMEGA_DEADLINE_STRING}, you will no longer be able to log into this website and see the work you’ve done over the past year.</span> To be able to reference some of the milestones you’ve completed and the information you’ve learned, we encourage you to follow these steps.
      </p>
    </>
  } else if (section === SECTIONS.M6_TAKEHOME) {
    return <>
      <header>
        <span className='section-title'>Save Your Milestone 6 Takehome.</span>
      </header>
      <p>The timelines you created in Milestone 6 can help you to keep track of the entire college application process. While your plans may still be changing, the general steps and dates will still apply.</p>
      <ol>
        <li key={`${SECTIONS.M6_TAKEHOME}-1`}>Go to <M6TakehomeLink />.</li>
        <li key={`${SECTIONS.M6_TAKEHOME}-2`}> Click the “print” button at the top of the first tab in your takehome.</li>
        <li key={`${SECTIONS.M6_TAKEHOME}-3`}>If you would like to print a physical copy, select your connected printer and hit “print.” Otherwise, select “Save as PDF” or “Print to PDF” and hit “save” or “print.” <span className='bold'>Note: You can always physically print your saved PDF later, when you have access to a printer.</span></li>
        <li key={`${SECTIONS.M6_TAKEHOME}-4`}>Repeat steps 2 and 3 for each tab in your Takehome.</li>
      </ol>
    </>
  } else if (section === SECTIONS.WILL_APPLY) {
    return <>
      <header>
        <span className='section-title'>Save the “Will Apply” List from Your Dashboard.</span>
      </header>
      <p>The “Will Apply” list you created in Milestone 12 is a great way to keep track of the schools you’re applying to, what programs they offer that you are interested in, and when the application deadlines are. This list can be a helpful reference for you over the next few months.</p>
      <ol>
        <li key={`${SECTIONS.WILL_APPLY}-1`}> Visit the <WillApplyLink /> tab on your dashboard.</li>
        <li key={`${SECTIONS.WILL_APPLY}-2`}>Click the “print” button at the top of this tab.</li>
        <li key={`${SECTIONS.WILL_APPLY}-3`}>If you would like to print a physical copy, select your connected printer and hit “print.” Otherwise, select “Save as PDF” or “Print to PDF” and hit “save” or “print.” <span className='bold'>Note: You can always physically print your saved PDF later, when you have access to a printer.</span> </li>
      </ol>
    </>
  } else if (section === SECTIONS.M12) {
    return <>
      <header>
        <span className='section-title'>Save Your College Search School Banks.</span>
      </header>
      <p>Follow these steps if you would like to keep track of all the schools you’ve saved in your School Banks in either Milestone 8 or Milestone 12, even the ones you didn’t add to your “Will Apply” list.</p>
      {m12Completed
        ? <ol>
          <li key={`${SECTIONS.M12}-1`}>Go to <SchoolBankLink />.</li>
          <li key={`${SECTIONS.M12}-2`}>Click the “print” button at the top of this page.</li>
          <li key={`${SECTIONS.M12}-3`}>If you would like to print a physical copy, select your connected printer and hit “print.” Otherwise, select “Save as PDF” or “Print to PDF” and hit “save” or “print.” <span className='bold'>Note: You can always physically print your saved PDF later, when you have access to a printer.</span></li>
        </ol>
        : <>
          <p className='bold danger'>If you didn’t complete M12, there is still time!</p>
          <ol>
            <li key={`${SECTIONS.M12}-1`}>Go to <M12Link />.</li>
            <li key={`${SECTIONS.M12}-2`}>Move through Milestone steps until you reach the page titled “Finalize List of Schools”.</li>
            <li key={`${SECTIONS.M12}-3`}>Click the “print” button at the top of this page.</li>
            <li key={`${SECTIONS.M12}-4`}>If you would like to print a physical copy, select your connected printer and hit “print.” Otherwise, select “Save as PDF” or “Print to PDF” and hit “save” or “print.” <span className='bold'>Note: You can always physically print your saved PDF later, when you have access to a printer.</span></li>
          </ol>
        </>
      }
    </>
  } else if (section === SECTIONS.MILESTONE_OMEGA) {
    return <>
      <header>
        <span className='section-title'>Save This Milestone.</span>
      </header>
      <p>This Milestone gives you many of the tools you will need during the application process. To ensure you can reference what you’ve learned in this Milestone, click the print button below.</p>
      <p style='margin-bottom: 0;'>If you would like to print a physical copy, select your connected printer and hit “print.” Otherwise, select “Save as PDF” or “Print to PDF” and hit “save” or “print.” <span className='bold'>Note: You can always physically print your saved PDF later, when you have access to a printer.</span></p>
    </>
  }
}

//CREATE A MODEL
class SavingYourWorkModel extends Question {
  getType() {
    return CUSTOM_TYPE;
  }
}



class SavingYourWorkBase extends SurveyElementBase {
  constructor(props) {
    super(props);
  }

  get question() {
    return this.props.question;
  }

  get name() {
    return this.props.question.name;
  }

  get m12Status() {
    return this.props.question[CUSTOM_PROPERTIES.M12_COMPLETED];
  }

  render() {
    if (!this.question) return null;

    return (
      <div className='section-root'>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <SavingYourWorkContents section={this.name} m12Completed={this.m12Status} />
        </div>
      </div>
    );
  }
}

export const SavingYourWork = withRouter(SavingYourWorkBase);

//CONFIGURE JSON SERIALIZATION
/*
addClass accepts: 
  name (from Model's getType method), 
  properties (array of objects used in JSON serialization, including all custom model properties)
  creator (a function that returns an instance of the model's class)
  parentName (name of parent class that this class extends)
*/
Serializer.addClass(
  CUSTOM_TYPE,
  [{
    name: CUSTOM_PROPERTIES.M12_COMPLETED,
    default: true
  }],
  () => new SavingYourWorkModel(""),
  "question"
);


//REGISTER THE MODEL
ReactQuestionFactory
  .Instance
  .registerQuestion(
    CUSTOM_TYPE,
    props => React.createElement(SavingYourWork, props)
  );
