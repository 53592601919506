import axios from 'axios';

// Axios wrapper functions:
// See https://axios-http.com/docs/req_config
export const apiRequest = async (method, path, params = {}) => {
  const config = {
    method: method,
    url: `${process.env.REACT_APP_API_ENDPOINT}${path}`,
    responseType: 'json',
    ...params
  };

  return axios.request(config)
    .then(response => response.data)
    .catch(error => {
      console.log(error)
      throw error;
    });
}

// Adds access token to header:
export const configureAuth = (getAccessToken) => {
  axios.interceptors.request.use(
    async (req) => {
      if (req.url.startsWith(process.env.REACT_APP_API_ENDPOINT)) {
        const accessToken = await getAccessToken();
        if (accessToken) {
          // use custom header as work around for Safari bug
          // see https://stackoverflow.com/questions/61411520/safari-http-request-header-is-not-reflecting-on-axios-interceptors-change
          req.headers['x-access-token'] = `Bearer ${accessToken}`;
        } else {
          // No token available, so cancel API request
          return {
            ...req,
            cancelToken: new axios.CancelToken((cancel) => cancel('cancel unauthorized request'))
          };
        }
      }
      return req;
    },
    async (err) => {
      return Promise.reject(err);
    }
  );
}

export const postEvent = (name, value = {}) => {
  return new Promise((resolve, reject) => {
    apiRequest("post", "/event", {
      data: { name, value }
    }).then(response => {
      resolve(response);
    }).catch(error => {
      console.log(error);
      reject(error);
    })
  });
}

export const genericPost = (name, body = {}) => {
  return new Promise((resolve, reject) => {
    apiRequest("post", `/${name}`, {
      data: body
    }).then(response => {
      resolve(response);
    }).catch(error => {
      console.log(error);
      reject(error);
    })
  });
}

export const getProfile = () => {
  return new Promise((resolve, reject) => {
    apiRequest('get', '/profile').then(response => {
      resolve(response);
    }).catch(error => {
      console.log(error);
      reject(false);
    });
  });
}

export const getAlerts = () => {
  return new Promise((resolve, reject) => {
    apiRequest('get', '/alerts').then(response => {
      resolve(response);
    }).catch(error => {
      console.log(error);
      reject(false);
    });
  });
}

export const dismissAlert = (id) => {
  return new Promise((resolve, reject) => {
    apiRequest('post', '/alerts', {
      data: {
        event: 'dismiss',
        id: id
      }
    }).then(response => {
      resolve(response);
    }).catch(error => {
      console.log(error);
      reject(false);
    });
  });
}

export const getMilestone = (milestone, params = {}) => {
  return new Promise((resolve, reject) => {
    apiRequest('get', '/milestone', {
      params: {
        milestone,
        ...params
      }
    }).then(response => {
      resolve(response);
    }).catch(error => {
      console.error(error);
      reject(false);
    });
  });
}

export const setMilestoneValue = (key, value, milestone) => {
  return new Promise((resolve, reject) => {
    apiRequest("post", "/milestone", {
      data: {
        milestone: milestone,
        key: key,
        value: value
      }
    }).then(response => {
      resolve(response);
    }).catch(error => {
      console.error(error);
      reject(error);
    })
  });
}

export const submitMilestone = (milestone) => {
  return new Promise((resolve, reject) => {
    apiRequest('post', '/milestone', {
      data: {
        milestone,
        key: 'submission'
      }
    }).then(response => {
      resolve(response);
    }).catch(error => {
      console.error(error);
      reject(error);
    })
  });
}