import React from 'react'
import { PrinterFill } from 'react-bootstrap-icons';
import { ConditionalWrapper } from 'lib/utils';
import { printIframe } from 'lib/print/iframeUtils';
import styles from 'styles/print-button.module.scss';

const PrintButton = ({ buttonText = '', containerModuleClassName }) => {

  const hasContainer = !!containerModuleClassName;

  const handlePrintClick = () => {
    printIframe();
  }

  return <ConditionalWrapper
    condition={hasContainer}
    wrapper={children => <div className={styles[containerModuleClassName]}>{children}</div>}>
    <button
      id={`print-button${hasContainer ? `-${containerModuleClassName}` : ''}`}
      className={hasContainer ? styles[`${containerModuleClassName}__button`] : styles.printButton}
      onClick={handlePrintClick}>
      <PrinterFill />{buttonText}
    </button>
  </ConditionalWrapper>
};


export default PrintButton;