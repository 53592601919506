import $ from 'jquery';
import { addInlineCssStylesFromCssObject, formatInlineCss } from './printUtils';
import { cssClassStyles } from "./printStyles";

export const iframeContentId = "printable_iframe_content";
const iframeId = "printable_iframe";

export const hidePrintableSurveyOnPage = (printableContent, targetDom) => {
  if ($(targetDom).find(`#${iframeContentId}`).length > 0) {
    $(targetDom).remove(`#${iframeContentId}`);
  }
  const formattedPrintableContent = addInlineCssStylesFromCssObject(printableContent);
  const contents_init = $(`<div id=${iframeContentId} style='display: none;'>`).html(`<div style="${formatInlineCss(cssClassStyles['sv-main'])}">${formattedPrintableContent}</div>`)

  $(targetDom).append(contents_init)
}

export const printIframe = () => {
  window.frames['print_pdf'].focus()
  window.frames['print_pdf'].print()
}

export const createPrintableIframe = () => {

  if (document.getElementById(iframeId)) {
    document.body.removeChild(document.getElementById(iframeId))
  }

  const iframe_init = document.createElement('iframe');
  iframe_init.name = "print_pdf";
  iframe_init.src = "about:blank";
  iframe_init.style.display = 'none';
  iframe_init.id = iframeId;

  const iframeBody = document.getElementById(iframeContentId).innerHTML;

  document.body.appendChild(iframe_init);

  const iframeElement = document.getElementById(iframeId);

  if (iframeElement) {
    const frameDoc = iframeElement.document || iframeElement.contentWindow.document;
    frameDoc.open();
    frameDoc.writeln(iframeBody);
    frameDoc.close();
  }
}
