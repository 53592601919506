import { preference_metrics } from 'lib/m8_dicts';
import { showValue, getEEC, getEECSign } from 'components/RecommendationsTable';
import { thousandsSeparator } from 'lib/format';
import { statesDict } from 'lib/miscellaneous';


const createPrintableSchoolTable = (schools, valuesHash, listTitle, activeQuestionId) => {
    const propsDict = preference_metrics.reduce((acc, curr) => {
      return { ...acc, [curr.prop]: [curr.text] };
    }, { notes: 'Notes' });
    const metricsForTable = preference_metrics.filter(metric => metric.name in valuesHash && valuesHash[metric.name] === 'yes')
      .concat([{ prop: 'notes', text: 'Notes' }]);

    let html = '';
    html += `<div id=${activeQuestionId}-print class='school-bank-printable-value'>`
    html += `<h1 class='question-title'">${listTitle}</h1>`;
    html += '<table style="width: 100%; border-collapse: collapse; font-family: Verdana, Helvetica, Arial sans-serif;">';
    schools.forEach(s => {
      html += '<tr>';
      html += `<td style="width: 50%; border: 1px solid black; padding: 5px;">`;
      html += `<div style="font-size: 14px; padding-left: 10px; padding-right: 10px; padding-top: 12px; padding-bottom: 10px;">`;
      html += `<span style="font-weight: bold; font-size: 18;">${s['INSTNM']} <span style="color: #101010; font-size: 14px; font-weight: 400 !important;">(${s['SCHOOL_TYPE']})</span><span><br/>`;
      html += `<div style="padding-top: 14px;">
      <table style="width: 100%; margin-bottom: 4px;">
      <tr>
      <td>Priority: ${s.priority}</td>
      <td>EEC: ${getEECSign(s)}${Number(s.roi) || Number(s.roi) === 0 ? `$${getEEC(s)}` : ''}</td>
      <td>Net Price: ${s['NET_PRICE'] ? '$' : ''}${thousandsSeparator(s['NET_PRICE'])}</td>
      </tr>
      </table>
      </div>`;
      ['DEADLINE', 'MAJORS'].forEach(k => {
        if (s[k]) {
          html += `<span style="font-size: 12px; font-weight: 600 !important;">${k in propsDict ? propsDict[k] : k}:</span><span style="font-size: 12px; font-weight: 400 !important;"> ${s[k]}</span><br />`;
        }
      });
      metricsForTable.forEach(metric => {
        const { prop: k } = metric;
        let value = s[k];
        if (k === 'STABBR') value = statesDict[k];
        if (k === 'ADM_RATE') value = showValue({ prop: 'ADM_RATE', format: 'percent' }, s);
        if (k === 'RETENTION_RATE') value = showValue({ prop: 'RETENTION_RATE', format: 'percent' }, s);
        if (k === 'COMPLETION_RATE') value = showValue({ prop: 'COMPLETION_RATE', format: 'percent' }, s);
        if (k === 'distance_from_hs') value = showValue({ prop: 'distance_from_hs', format: 'miles' }, s);
        if (!['INSTNM', 'willApply', 'will_apply', 'school_type', 'SCHOOL_TYPE', 'roi', 'priority', 'NET_PRICE'].includes(k) && s[k]) {
          html += `<span style="font-size: 12px; font-weight: 600 !important;">${k in propsDict ? propsDict[k] : k}:</span><span style="font-size: 12px; font-weight: 400 !important;"> ${value}</span><br />`;
        }
      });
      html += `</div></td></tr>`;
    });
    html += '</table></div>';

    return html;
  }

export default createPrintableSchoolTable;